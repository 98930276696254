import React from 'react'
import ChairPerson from './ChairPerson'
import Heading from '../../../components/school/ui/Heading'

const Vision = () => {
  const heading = "Vision & Mission";
  return (
    <section className='flex flex-col items-center  justify-center py-10 px-4 sm:px-6 md:px-10 max-w-7xl mx-auto gap-y-5'>
       <div className=" flex flex-col justify-center   ">
        <div className='flex flex-col justify-start'>
           <Heading heading={heading}/>
          <div className='mt-8 pl-4'>
            <h4 className='text-xl sm:text-3xl md:text-4xl font-semibold text-gray-700'> Our Vision </h4>
            <p className='text-base md:text-lg text-gray-600 my-2'>
               "THE EDUCATORS" envisions quality education as the key resource for national development, delivered to a cross section of our society through sustainable projects
            </p>
            <h4 className='text-xl sm:text-3xl md:text-4xl  font-semibold text-gray-700'> Mission Statement
            </h4>
            <p className='text-base md:text-lg text-gray-600 my-2'>
              "THE EDUCATORS" aims for a quality learning experience for the students and quality training for the faculty by providing excellence in education within our socio-cultural framework, bringing forth benefit to students, community and the country.
            </p>
            <h4 className='text-xl sm:text-3xl md:text-4xl font-semibold text-gray-700'> Quality Policy
            </h4>
            <p className='text-base md:text-lg text-gray-600 my-2'>
               To Serve our clients and maintain our position as a leader in school education
               <span className='block'>
                  We intend to achieve this goal by:
               </span>
            </p>
            <ul className='list-disc text-gray-600 ml-6'>
                <li>
                    <p className='text-base md:text-lg text-gray-600 my-2'>
                      Understanding that quality means complete conformance to agreed customer and user expectations, requirements & satisfaction standards.
                    </p>
                </li>
                <li>
                  <p className='text-base md:text-lg text-gray-600 my-2'>
                    Understanding that quality is everybody’s direct responsibility and they should continually endeavor to improve processes to ensure customer satisfaction.                   
                 </p>
                </li>
                <li>
                    <p className='text-base md:text-lg text-gray-600 my-2'>
                      Putting our best effort every time for timely achievement of organisation and departmental targets, not compromising on quality, legal and regulatory requirements.
                    </p>
                </li>
                <li>
                    <p className='text-base md:text-lg text-gray-600 my-2'>
                      Ensuring control of operational variations, through continuous training of personnel for improving service skills and know-how.                    </p>
                </li>
                <li>
                    <p className='text-base md:text-lg text-gray-600 my-2'>
                      Operating all our work places in a safe and healthy environment and improve employee satisfaction.                    </p>
                </li>
                <li>
                    <p className='text-base md:text-lg text-gray-600 my-2'>
                      Ensuring our quality of services by improving quality bonds with our suppliers and vendors.
                    </p>
                </li>
                <li>
                    <p className='text-base md:text-lg text-gray-600 my-2'>
                      We must all be dedicated to this mission. Our reputation depends on all of us working as a team to satisfy and retain the single most important person in our business, "the student".                    </p>
                </li>
            </ul>
          </div>

        </div>
        <ChairPerson/>
       </div>
    </section>
  )
}

export default Vision
import React from 'react';
import Select from 'react-select';

// Define the options for the select input
const options = [
  { value: 'John', label: 'John' },
  // Add more options as needed
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#1f2937' : '#f9fafb',
    borderColor: state.isFocused ? '#3b82f6' : '#d1d5db',
    color: state.isFocused ? '#fff' : '#111827',
    '&:hover': {
      borderColor: state.isFocused ? '#fffff' : '#d1d5db'
    },
    borderRadius: '0.5rem',
    padding: '3px',
    fontSize: '0.875rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#fff' : '#111827',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9ca3af',
  }),
};

const SelectInput = () => (
  <div>
    <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      First name
    </label>
    <Select
      options={options}
      styles={customStyles}
      placeholder="John"
      required
      inputId="first_name"
    />
  </div>
);
export default SelectInput;

import React from 'react'
import heroImage from '../../assets/main/img/alsafheroimage.png'
const Herosection = () => {
  return (
    <div className='flex flex-col-reverse sm:flex-row relative  justify-between items-center py-10 gap-y-4 sm:gap-y-0'>
        <div className='absolute right-0 top-10 z-10 '>
        <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.5 0L50.6723 28.3277L79 39.5L50.6723 50.6723L39.5 79L28.3277 50.6723L0 39.5L28.3277 28.3277L39.5 0Z" fill="white"/>
</svg>
        </div>

        <div className='text-center sm:text-left'>
            <h1 className='text-xl sm:text-3xl sm:mb-5 mb-3'>
                WELCOME TO
            </h1>
            <h1 className='text-4xl sm:text-6xl mb-4 font-semibold text-colorPrimaryMain'>
            AL SAFA FOUNDATION
            </h1>
            <h1 className='text-xl sm:text-3xl mb-5 sm:mb-10'>
            Revealing the Path to Spiritual Connection
            </h1>
            <button className='bg-colorPrimaryMain px-5 py-2 rounded text-white z-10'>
                EXPLORE
            </button>

        </div>
        <div className='w-1/2'>
        <img src={heroImage} className='' alt="" />
        </div>
        <div className='absolute left-2 bottom-2 2xl:bottom-28 md:hidden lg:block '>
        <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.5 0L50.6723 28.3277L79 39.5L50.6723 50.6723L39.5 79L28.3277 50.6723L0 39.5L28.3277 28.3277L39.5 0Z" fill="white"/>
</svg>
        </div>
    </div>
  )
}

export default Herosection
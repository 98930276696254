import React from 'react'
import CourseCard from './CourseCard'

const CourcePlan = () => {
    return (
        <div className='freeCards  flex flex-col  justify-center p-10 max-w-7xl mx-auto gap-y-5'>
            <h4 className='font-medium text-gray-800 text-xl sm:text-2xl md:text-3xl'> Course Levels</h4>
            <div className="cards block sm:flex gap-4 lg:gap-8 ">
              <div className="card1 mb-2 sm:mb-0  w-full md:w-2/5 lg:1/3">
                 <CourseCard  />
              </div>
              <div className="card2 mt-2 sm:mt-0   w-full md:w-2/5 lg:1/3">
                 <CourseCard  />
              </div>
              <div className="card2 mt-2 sm:mt-0   w-full md:w-2/5 lg:1/3">
                 <CourseCard  />
              </div>
    
            </div>
        </div>
      )
}

export default CourcePlan
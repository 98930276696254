import React from 'react'
import ContactSection from './ContactSection'

const Contact = () => {
  return (
    <section className='pt-16 md:pt-28 '>
        <ContactSection/>
    </section>
  )
}

export default Contact
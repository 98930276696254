import React from 'react'
import HeroSection from './HeroSection'
import Benefits from './Benefits'
import Offers from './Offers'
import FeeSection from './FeeSection'
import MessageSection from './MessageSection'
const Home = () => {
  return (
    <div className='b-2 b-red-700'>
      <HeroSection className="b-2 bg-red-500" style={{border:"2px solid red"}}/>
      <Benefits/>
      <Offers/>
      <FeeSection/>
      <MessageSection/>
    </div>
  )
}

export default Home
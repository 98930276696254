import React from 'react';
import img1 from "../../../assets/e-learning/img/e-learning-about-foculty-pic1.jpg";
import img2 from "../../../assets/e-learning/img/e-learning-about-foculty-pic2.jpg";
import img3 from "../../../assets/e-learning/img/e-learning-about-foculty-pic3.jpg";

const Images = () => {
  return (
    <div className='w-screen grid grid-cols-1 sm:grid-cols-3   '>
        <div className="pic1">
          <img className='w-full' src={img1} alt="foculty member profile 1" />
        </div>
        <div className="pic1">
          <img className='w-full' src={img2} alt="foculty member profile 2" />
        </div>
        <div className="pic1">
          <img className='w-full' src={img3} alt="foculty member profile 3" />
        </div>
        
    </div>
  )
}

export default Images
import React from 'react';
import Result from './Result';


const Examinations = () => {
  return (
    <div className='w-full  py-8'>
        <h4 className='text-lg md:text-xl text-gray-700'>
          The academic year commences from March and continues till February of next year. There are 2 terms in an academic year
        </h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-4">
            <div className="bg-schoolColorMain px-4 py-5 sm:px-4 sm:py-6 md:px-8 md:py-8  rounded-xl ">
                <h2 className='font-semibold md:font-extrabold text-gray-100  text-2xl sm:text-3xl md:text-4xl'>
                    Term I
                </h2>
                <p className='text-lg sm:text-xl md:text-2xl text-gray-100'>
                    March to September
                </p>
            </div>
            <div className="bg-teal-400  px-4 py-5 sm:px-4 sm:py-6 md:px-8 md:py-8 rounded-xl ">
                <h2 className='font-semibold md:font-extrabold text-gray-100  text-2xl sm:text-3xl md:text-4xl'>
                    Term II
                </h2>
                <p className='text-lg sm:text-xl md:text-2xl text-gray-100'>
                    October to February
                </p>
            </div>

        </div>
        <p className='text-red-600 mt-2 font-medium text-base md:text-lg'>
            NOTE: An academic year may vary according to regional BISE requirements.
        </p>

        <div >

        </div>

    </div>


  )
}

export default Examinations
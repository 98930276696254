import React from 'react'
import SyllabusSection from './SyllabusSection'

const Syllabus = () => {
  return (
    <div>
       <SyllabusSection/>
    </div>
  )
}

export default Syllabus
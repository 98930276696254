import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Slider from "react-slick";
import './../../../App.css';
import reviewImg from "../../../assets/school/img/home-review.png";
import { Autoplay } from "swiper/modules";
const Review = () => {
  const settings = {
    infinite: true,
    speed: 500,
    arrows:false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    dots: true,
    Arrows: false,
    autoplay: true,
    autoplaySpeed: 2000
  };

  const carouselImgs = [
    {
      id: 1,
      pic: reviewImg,
      about: "Studying at The Educators was definitely the best decision my parents took for me. This institution has a long journey of success and the teachers are really helpful. They constantly motivate and inspire you to work harder and achieve your dreams. That is why I Love The Educators.",
      name: "ZAHRA NAWAZ",
      award: "1st Position in BWP Board 1083/1100",
    },
    {
      id: 2,
      pic: reviewImg,
      about: "Studying at The Educators was definitely the best decision my parents took for me. This institution has a long journey of success and the teachers are really helpful. They constantly motivate and inspire you to work harder and achieve your dreams. That is why I Love The Educators.",
      name: "ZAHRA NAWAZ",
      award: "1st Position in BWP Board 1083/1100",
    },
    {
      id: 3,
      pic: reviewImg,
      about: "Studying at The Educators was definitely the best decision my parents took for me. This institution has a long journey of success and the teachers are really helpful. They constantly motivate and inspire you to work harder and achieve your dreams. That is why I Love The Educators.",
      name: "ZAHRA NAWAZ",
      award: "1st Position in BWP Board 1083/1100",
    },
  ];

  return (
    <section className=" bg-gray-100">
      <div className="flex justify-center items-center mx-auto p-4 sm:px-8 pt-8 pb-20 md:p-20 max-w-7xl">
        <div className="w-full">
          <Slider {...settings}>
            {carouselImgs.map(({ id, pic, name, about, award }) => (
              <div className="overflow-hidden" key={id}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                  <div className="md:col-span-2">
                    <p className="text-gray-800 mb-2">{about}</p>
                    <h2 className="text-schoolColorMain mb-1 text-xl md:text-2xl lg:text-4xl">{name}</h2>
                    <h4 className=" text-base sm:text-xl lg:text-2xl text-gray-500">{award}</h4>
                  </div>
                  <div className="flex justify-center md:justify-end">
                    <img src={pic} className=" h-auto" alt="carousel img" />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Review;

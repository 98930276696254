import React from 'react';
import Detail from './Details';
import FreePlan from './FreePlan';
import CourcePlan from './CourcePlan';
import { useLocation } from 'react-router-dom';
const CourseDetail = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  
  // Extracting title and description from the query parameters
  const title = query.get('title');
  const description = query.get('description');
  return (
    <div className='bg-colorBg'>
        <Detail title={title} description={description} />
        <FreePlan/>
        <CourcePlan/>

        

    </div>
  )
}

export default CourseDetail
import React from 'react'
import FreeCard from './FreeCard'

const FreeCards = () => {
  const planA = " A";
  const planB = " B";

  
  const days2=[
    {
      "country": "UK",
      "price": "£25"
    },
    {
      "country": "USA",
      "price": "$35"
    },
    {
      "country": "Australia",
      "price": "$50"
    }
  ]
  const days5 = [
    {
      "country": "UK",
      "price": "£35"
    },
    {
      "country": "USA",
      "price": "$50"
    },
    {
      "country": "Australia",
      "price": "$70"
    }
  ]
  return (
    <div className='freeCards  flex flex-col  justify-center p-10 max-w-7xl mx-auto gap-y-5'>
        <h4 className='font-medium text-gray-800 text-xl sm:text-2xl md:text-3xl'> Free Plan</h4>
        <div className="cards block sm:flex gap-8 ">
          <div className="card1 mb-2 sm:mb-0  w-full md:w-2/5 lg:1/3">
             <FreeCard plan={planA} data={days2} />
          </div>
          <div className="card2 mt-2 sm:mt-0   w-full md:w-2/5 lg:1/3">
             <FreeCard plan={planB} data={days5} />
          </div>

        </div>
    </div>
  )
}

export default FreeCards
import React from 'react'
import academicPic from "../../../assets/school/img/admission-academic.png";
const Academics = () => {
    const academicCalender = [
        {
            id: 1,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 2,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 3,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 4,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 5,
            title: "Pre-school Summer",
            year: "2023-2024"
        },

        {
            id: 6,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 7,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 8,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 9,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 10,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 11,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
        {
            id: 12,
            title: "Pre-school Summer",
            year: "2023-2024"
        },
    ]
  return (
    <div className='w-full  py-8'>
       <p className="text-base sm:text-lg md:text-xl text-gray-700 my-2">
          The Educators Academic/Training Department works very closely with teachers for a common objective: to improve the quality of teaching and facilitate learning in every way. Our high quality, standardized curriculum has produced some of the best results in the country. The Academic /Training Department has a robust Professional Development Programme for teacher training and another support function is to provide subject specific Term wise CRP (Curriculum Resource Packs) Daily Lesson Plans (DLPs) and Assessment Packs to teachers.
       </p>
       <p className="text-base sm:text-lg md:text-xl text-gray-700 my-2">
          DLPs allow teachers to focus on lesson implementation; this helps in improving teaching standards by taking away the daily planning time and effort put in by the teachers for the lesson planning. DLPs have been structured around the Curriculum Resource Packs (CRPs) subjects and incorporate interactive teaching and learning approaches. Curriculum Resource Packs comprise of a scheme of studies related to a centralized book list of private and government publications. CRPs are developed for two terms respectively. The teachers use these CRPs and DLPs to ensure effective teaching and learning in the classrooms.       
        </p>
        <h4 className='font-semibold mb-8 md:font-bold lg:font-extrabold text-2xl mt-4 sm:text-3xl md:text-4xl text-schoolColorMain text-center'>
            Academic Calender
        </h4>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
            {
                academicCalender.map(({id, title, year}) => (
                 <div className='px-3 py-2 flex gap-2  bg-gray-300 rounded-xl hover:bg-schoolColorMain cursor-pointer' key={id}>
                <img src={academicPic} alt="academic " />
                <div className='text-white'>
                   <h4 className=' md:text-xl lg:text-2xl font-semibold '> {title}</h4>
                   <p className='text-base lg:text-xl '> {year}</p>
                </div>

                 </div>

                ))
            }

        </div>

    </div>
  )
}

export default Academics
import React from 'react'
import FeeCardBoarder from './FeeCardBoarder'
import FeeCardScholar from './FeeCardScholar'

const FeePage = () => {
  const data=[
    {
      admissiontime:{
        item:[
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          }
          
        ],
        total:'8500',

      },
      monthlyfee:{
        item:[
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          }
          
        ],
        total:'2500'


      }

    },
    {
      admissiontime:{
        item:[
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          }
          
        ],
        total:'8500',

      },
      monthlyfee:{
        item:[
          {
            title:'Admission Fee',
            amount:'2000',
          },
          {
            title:'Admission Fee',
            amount:'2000',
          }
          
        ],
        total:'2500'


      }

    },
  ]
  return (
    <div className='bg-colorBgMain'>
      
          <FeeCardBoarder />
          <FeeCardBoarder />
       
    </div>
  )
}

export default FeePage
import React from "react";
import eventPic from "../../../assets/school/img/eventPic.jpg";

const NewsSection = () => {
  return (
    <section className="flex flex-col items-center w-[100%] justify-center pt-24 md:pt-40  max-w-7xl mx-auto gap-y-5">
      <div className=" flex flex-col justify-center items-start p-2      w-[100%]">
          <h1 className="bg-schoolColorMain text-4xl w-full md:text-6xl block text-white font-bold  rounded-lg  text-left p-3 md:p-6">
            Vision & Mission
          </h1>
          <div className="px-4 w-full ">
          <h2 className="text-3xl md:text-4xl font-medium text-gray-700 my-6 ">
            THE EDUCATORS and Tech Valley Launch: Aghaaz
          </h2>
          <img src={eventPic} className="w-full" alt="event Pic" />

          <p className="my-2 text-xl text-gray-900 leading-loose  pr-2">
            THE EDUCATORS, in collaboration with Tech Valley Pakistan, Google for Education, the Ministry of Federal Education and Professional Training, and the National University of Sciences & Technology (NUST), as a consortium unveiled Aghaaz, a catalyst for education development, a ground-breaking initiative poised to ignite a revolution in Pakistan's educational landscape. This pilot project, set to take root at The Educators Capital Campus G11 Islamabad, will serve as a fertile ground for innovation, where the creation of digital IDs for teachers and students will enable the digital transformation and scaling-up of classrooms of THE EDUCATORS through AI-powered Google Tools for Education.
          </p>

          <img src={eventPic} alt="pic" className="w-2/3 my-4" />

          <p className="my-2 text-xl text-gray-900 leading-loose  pr-2">
            THE EDUCATORS, in collaboration with Tech Valley Pakistan, Google for Education, the Ministry of Federal Education and Professional Training, and the National University of Sciences & Technology (NUST), as a consortium unveiled Aghaaz, a catalyst for education development, a ground-breaking initiative poised to ignite a revolution in Pakistan's educational landscape. This pilot project, set to take root at The Educators Capital Campus G11 Islamabad, will serve as a fertile ground for innovation, where the creation of digital IDs for teachers and students will enable the digital transformation and scaling-up of classrooms of THE EDUCATORS through AI-powered Google Tools for Education.
          </p>
          <p className="my-2 text-xl text-gray-900 leading-loose  pr-2">
            THE EDUCATORS, in collaboration with Tech Valley Pakistan, Google for Education, the Ministry of Federal Education and Professional Training, and the National University of Sciences & Technology (NUST), as a consortium unveiled Aghaaz, a catalyst for education development, a ground-breaking initiative poised to ignite a revolution in Pakistan's educational landscape. This pilot project, set to take root at The Educators Capital Campus G11 Islamabad, will serve as a fertile ground for innovation, where the creation of digital IDs for teachers and students will enable the digital transformation and scaling-up of classrooms of THE EDUCATORS through AI-powered Google Tools for Education.         
          </p>


          </div>
      </div>
    </section>
  );
};

export default NewsSection;

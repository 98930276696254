import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { PublicRoute } from "./PublicRoutes";
import Layout from "../components/e-learning/layout";
import Home from "../pages/elearning/home";
import Course from "../pages/elearning/course";
import MainHome from "../pages/tahfeez-ul-quran/main-home/index";
import Facilities from "../pages/tahfeez-ul-quran/facilities/Facilities";
import Syllabus from "../pages/tahfeez-ul-quran/syllabus";
// import About from "../pages/tahfeez-ul-quran/about";
import QuranLayout from "../components/tahfeez-ul-quran/layout";
import Main from "../pages/main";
import ElearningLayout from "../components/e-learning/layout";
// import QuranHome from "../pages/tahfeez-ul-quran/main-home/index";
import Uniform from "../pages/tahfeez-ul-quran/uniform";
import FeePage from "../pages/tahfeez-ul-quran/fee";
import Admission from "../pages/tahfeez-ul-quran/admission";
import ElearningAboutus from "../pages/elearning/aboutus";
import Fee from "../pages/elearning/fee";
import Registartion from "../pages/elearning/registration";
import ContactUs from "../pages/elearning/contactus";
import QuranAboutPage from "../pages/tahfeez-ul-quran/about/index";
import CourseDetail from "../pages/elearning/course-detail";
import SchoolLayout from '../components/school/layout';
import HomePage from '../pages/school/home';
import AboutUs from '../pages/school/aboutus/index';
import NewsSection from '../pages/school/aboutus/NewsSection';
import Gallery from '../pages/school/gallery';
import Contact from '../pages/school/contact';
import SchoolAdmission from '../pages/school/admission';


const Routers = () => {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path="/elearning/home"
            element={
              <ElearningLayout>
                <Home/>
              </ElearningLayout>
            }
          />
          <Route
            path="/elearning/aboutus"
            element={
              <ElearningLayout>
                <ElearningAboutus/>
              </ElearningLayout>
            }
          />
          <Route
            path="/elearning/courses"
            element={
              <ElearningLayout>
                <Course/>
              </ElearningLayout>
            }
          />
          <Route
            path="/elearning/courses/coursedetails"
            element={
              <ElearningLayout>
                <CourseDetail/>
              </ElearningLayout>
            }
          />
          <Route
            path="/elearning/fee"
            element={
              <ElearningLayout>
                <Fee/>
              </ElearningLayout>
            }
          />
          <Route
            path="/elearning/registration"
            element={
              <ElearningLayout>
                <Registartion/>
              </ElearningLayout>
            }
          />
          <Route
            path="/elearning/contactus"
            element={
              <ElearningLayout>
                <ContactUs/>
              </ElearningLayout>
            }
          />
          <Route
            path="/"
            element={
             
                <Main/>
             
            }
          />
           <Route
            path="/elearning/course"
            element={
              <Layout>
                <Course />
              </Layout>
            }
          />
           <Route
            path="/quran/home"
            element={
              <QuranLayout>
                 <MainHome/>
              </QuranLayout>
            }
          />
           <Route
            path="/quran/facilities"
            element={
              <QuranLayout>
                 <Facilities/>
              </QuranLayout>
            }
          />
           <Route
            path="/quran/syllabus"
            element={
              <QuranLayout>
                 <Syllabus/>
              </QuranLayout>
            }
          />
           <Route
            path="/quran/uniform"
            element={
              <QuranLayout>
                 <Uniform/>
              </QuranLayout>
            }
          />
           <Route
            path="/quran/fee"
            element={
              <QuranLayout>
                 <FeePage/>

              </QuranLayout>
            }
          />
          <Route
            path="/quran/admission"
            element={
              <QuranLayout>
                 <Admission/>

              </QuranLayout>
            }
          />
          <Route
            path="/quran/aboutus"
            element={
              <QuranLayout>
                 <QuranAboutPage/>
              </QuranLayout>
            }
          />

          {/* // school Routes */}
          <Route
              path='/school/home'
              element= {
                <SchoolLayout>
                    <HomePage/>
                </SchoolLayout>
              }
            />
            <Route
              path='/school/aboutus'
              element= {
                <SchoolLayout>
                    <AboutUs/>
                </SchoolLayout>
              }
            />
            <Route
              path='/school/aboutus/news-event'
              element= {
                <SchoolLayout>
                    <NewsSection/>
                </SchoolLayout>
              }
            />
            <Route
              path='/school/gallery'
              element= {
                <SchoolLayout>
                    <Gallery/>
                </SchoolLayout>
              }
            />
            <Route
              path='/school/contact'
              element= {
                <SchoolLayout>
                    <Contact/>
                </SchoolLayout>
              }
            />
            <Route
              path='/school/admission'
              element= {
                <SchoolLayout>
                    <SchoolAdmission/>
                </SchoolLayout>
              }
            />

        </Routes>
      </HashRouter>
    </>
  );
};

export default Routers;

import React from 'react';
import "../../../App.css";
const Procedure = () => {
    const tableData = [
        {
            id: 1,
            classLevel: "PG",
            minimum: "2 Years 6 Months",
            Maximum: " 3 Years 6 Months"
        },
        {
            id: 2,
            classLevel: "Nursery",
            minimum: "2 Years 6 Months",
            Maximum: " 4 Years 6 Months"
        },
        {
            id: 3,
            classLevel: "KG",
            minimum: "4 Years",
            Maximum: " 5 Years"
        },
        {
            id: 4,
            classLevel: "Class I",
            minimum: "5 Years",
            Maximum: " 6 Years"
        },
        {
            id: 5,
            classLevel: "Class II",
            minimum: "6 Years",
            Maximum: " 7 Years"
        },
        {
            id: 6,
            classLevel: "Class III",
            minimum: "7 Years",
            Maximum: " 8 Years"
        },
        {
            id: 7,
            classLevel: "Class IV",
            minimum: "8 Years",
            Maximum: " 9 Years"
        },
        {
            id: 8,
            classLevel: "Class V",
            minimum: "9 Years",
            Maximum: "10 Years"
        },
        {
            id: 9,
            classLevel: "Class VI",
            minimum: "10 Years",
            Maximum: " 11 Years"
        },
        {
            id: 10,
            classLevel: "Class VII",
            minimum: "11 Years",
            Maximum: " 12 Years"
        },
        {
            id: 11,
            classLevel: "Class VIII",
            minimum: "12 Years",
            Maximum: " 13 Years"
        },
        {
            id: 12,
            classLevel: "Class IX",
            minimum: "13 Years",
            Maximum: " 14 Years"
        },
        {
            id: 13,
            classLevel: "PG",
            minimum: "14 Years",
            Maximum: " 15 Years"
        },
    ]
  return (
    <div className=' px-2'>
        <div className="admissionProcedure justify-center items-center flex py-8 p-2  flex-wrap gap-4">
           <div className="step1 w-80 rounded-t-full border border-schoolColorMain text-center p-4  bg-white">
              <h2 className='font-bold md:font-extrabold text-3xl md:text-5xl text-schoolColorMain  mt-2'>
                1
              </h2>
              <p className='text-schoolColorMain text-base md:text-xl mt-8 font-semibold px-2'>
                Schedule for date of interview and the admission test is announced
              </p>
           </div>
           <div className="step1 w-80 rounded-t-full border border-schoolColorMain text-center p-4  bg-white">
              <h2 className='font-bold md:font-extrabold text-3xl md:text-5xl text-schoolColorMain  mt-2'>
                2
              </h2>
              <p className='text-schoolColorMain text-base md:text-xl mt-8 font-semibold px-2'>
                An oral and written test is conducted as per schedule            
              </p>
           </div>
           <div className="step1 w-80 rounded-t-full border border-schoolColorMain text-center p-4  bg-white">
              <h2 className='font-bold md:font-extrabold text-3xl md:text-5xl text-schoolColorMain  mt-2'>
                3
              </h2>
              <p className='text-schoolColorMain text-base md:text-xl mt-8 font-semibold px-2'>
                A child must first clear the written test before qualifying for interview    
              </p>
           </div>
           <div className="step1 w-80 rounded-t-full border border-schoolColorMain text-center p-4  bg-white">
              <h2 className='font-bold md:font-extrabold text-3xl md:text-5xl text-schoolColorMain  mt-2'>
                4
              </h2>
              <p className='text-schoolColorMain text-base md:text-xl mt-8 font-semibold px-2'>
                The child and his/her parents are interviewed by the School Head             
             </p>
           </div>
           <div className="step1 w-80 rounded-t-full border border-schoolColorMain text-center p-4  bg-white">
              <h2 className='font-bold md:font-extrabold text-3xl md:text-5xl text-schoolColorMain  mt-2'>
                5
              </h2>
              <p className='text-schoolColorMain text-base md:text-xl mt-8 font-semibold px-2'>
                Should the child clear admission test and interview, the admission fee invoice is to be submitted at the school to complete the admission process              </p>
           </div>


        </div>

        <p className='text-center text-gray-600  text-sm sm:text-base  px-4 md:px-10   my-4 md:my-8'>
          There is no written test for pre-school. The admission test for primary and secondary school is based on the following subjects and classes
        </p>

        <div className="tests grid grid-cols-1 sm:grid-cols-2 gap-2 ">
            <div className="bg-schoolColorMain px-2 pl-4 py-4  text-white rounded-lg">
                <p>
                   Test for 
                </p>
                <h4 className='font-semibold sm:font-bold md:font-extrabold text-2xl sm:text-3xl md:text-4xl'>
                    KG & 1
                </h4>
                <p className='text-base sm:text-lg md:text-xl '>
                    Based on
                </p>
                <p>
                  English, Urdu, Mathematics and General Knowledge (oral)
                </p>
            </div>
            <div className="bg-teal-400 px-2 pl-4 py-4  text-white rounded-lg">
                <p>
                   Test for 
                </p>
                <h4 className='font-semibold sm:font-bold md:font-extrabold text-2xl sm:text-3xl md:text-4xl'>
                    Class II - VII
                </h4>
                <p className='text-base sm:text-lg md:text-xl '>
                    Based on
                </p>
                <p className='text-base'>
                  English, Urdu, Mathematics and Science
                </p>
            </div>
        </div>
        <p className='px-4 md:px-10  py-4 bg-gray-700 text-gray-200 rounded-lg mt-4 md:mt-6'>
           admission test is structured to fit onto one page with questions from all subjects and each paper is for 100 marks, which are spread evenly across all subjects.<br/>
           No other personnel other than the designated invigilators are allowed at the time of the test. The invigilators provide guidance and support to the children during the test if required.
        </p>

       <h4 className='text-gray-800 text-3xl md:text-4xl mt-4 md:mt-8'>
         Age Limitations
       </h4>
       <div className="ageLimit p-4">
        <table className='w-full '>
                <thead className=' w-full font-medium border-b border-gray-200'>
                    <td className=' w-1/3 text-blue-600  font-medium  pb-2 text-center'>
                        Class Level
                    </td>
                    <td className='w-1/3 text-blue-600 text-center'> 
                       Minimum
                    </td>
                    <td className='text-blue-600 w-1/3 text-center'>
                        Maximum
                    </td>
                    {
                        tableData.map(({id, classLevel, minimum, Maximum}) =>(
                            <tr className=' border-y border-gray-200 text-gray-600' key={id}>
                                <td className='py-2  w-1/3 text-center'> {classLevel} </td>
                                <td className='w-1/3 text-center'> {minimum} </td>
                                <td className='w-1/3 text-center'> {Maximum} </td>
                             </tr>
                        ))
                    }

                </thead>
        </table>

        <p className='py-2 mt-4 text-gray-600'>
            Note: children who do not fall within in the defined age limits can be considered for acceptance in exceptional cases.
        </p>

       </div>
        
    </div>
  )
}

export default Procedure
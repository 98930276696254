import React from 'react'
import educationImg from '../../assets/main/img/educationDegree.png'
import EducationCard from './EducationCard'
import { edjucationIcon } from '../../assets/e-learning/svg/svgs'

const Education = () => {
  const data = [
    {
      id: 1,
      title: "Schoolings",
      icon: edjucationIcon.schoolIcon,
      description: "Daily Discussion about the history of Islam",
      link: "/school/home"
    },
    {
      id: 2,
      title: "Tehfeez-ul-Quran",
      icon:edjucationIcon.tehfeezulQuran,
      description: "Nazra and  Hifz Quran  according to the classes",
      link: "/quran/home"
    },
    {
      id: 3,
      title: "Shariah Courses",
      icon:edjucationIcon.shariahCourses,
      description: "Courses such as Nazra,  Tafseer-e-Quran and other much more",
      link: "/"
    },
    {
      id: 4,
      title: "Dars-e-Nizami",
      icon: edjucationIcon.darseNizam,
      description: "Daily Discussion about the history of Islam",
      link: "/"
    },
    {
      id: 5,
      title: "eLearning",
      icon:edjucationIcon.eLearning,
      description: "Nazra and  Hifz Quran  according to the classes",
      link: "/elearning/home"
    },
  ]
  return (
   <div className='py-10'>
     <div className='flex flex-col  sm:flex sm:flex-row justify-between items-center '>
        <div>
            <h1 className='text-[#96BB7C] text-lg mb-2'>Al-SAFA</h1>
            <h1 className='text-colorPrimaryMain text-3xl sm:text-5xl font-bold mb-3'>Quality Education</h1>
            <p className='text-lg text-justify'>Live Life according to the Deen and win the Duniya and prepare yourself for Akhira as well</p>
        </div>
        <img className='w-1/2 sm:w-auto' src={educationImg} alt="" />
    </div>
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 '>
      {
        data.map(({ id, title, icon, description, link}) => (
          <EducationCard key={id} icon={icon} title={title} description={description} link={link} />
    
        ))
      }
    </div>
   </div>
  )
}

export default Education
import React from 'react'
import uniformImg from "../../../assets/tahfeezul -quran/img/tahfeezul-quran-uniform.jpg";


const UniformInstruction = () => {
  return (
    <div className="uniformLayout flex flex-col items-center  justify-center p-10 max-w-7xl mx-auto gap-y-5">
      <div className="uniformElements  flex flex-col  items-center  mt-14 sm:mt-16 md:mt-20 ">
      <h2 className="mr-auto text-xl mb-8 sm:text-2xl md:text-3xl lg:text-4xl font-bold  text-blue-600  ">
       Uniform
      </h2>

      <div className="syllabusImg relative">
        <img src={uniformImg} className='w-full h-full ' alt="uniform  img" />
        <div className="uniformInstructions w-fit rounded-lg bg-gradient-to-r from-colorPrimaryMain to-blue-500   px-4 pr-8 py-4 sm:absolute bottom-4 left-4 mt-4 sm:mt-0 ">

            <p className='text-gray-100 mb-2 '> White shalwar kameez  </p>
            <p className='text-gray-100 mb-2 '> White Full sleeved Jersey in winter(Maroon) kameez  </p>
            <p className='text-gray-100 mb-2'> Black Shoes  </p>
            <p className='text-gray-50'> Al-Safa Cape </p>
        </div>
      </div>
    </div>
    </div>
  )
}

export default UniformInstruction
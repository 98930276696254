import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Message from "./Message";

function FounderSlider() {
  let sliderRef = useRef(null);
  const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
               
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                
            }
        },
    ]
  };
  return (
    <>
       {/* <div className="slider-container md:px-10 sm:hidden">
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        <FeeCardMini type={`Basic`}/>
        <FeeCardMini type={`Intermediate`}/>
        <FeeCardMini type={`Advance`}/>

        
       
        
      </Slider>
     
    </div> */}
    <div className=" hidden sm:block lg:mx-28">
      <Slider ref={slider => (sliderRef)} {...settings}>
        
        <Message/>
        <Message/>
        <Message/>
      </Slider>
     
    </div></>
 
  );
}
export default FounderSlider;

import React from 'react'

const Testimonials = () => {
    const testimonials = [
      {
        id: 1,
        title: "02",
        subTitles: "Branches"
      },
      {
        id: 2,
        title: "2000",
        subTitles: "Students"
      },
      {
        id: 3,
        title: "50",
        subTitles: "Teachers"
      },
      {
        id: 4,
        title: "50+",
        subTitles: "Short Courses"
      },
    ]
  return (
    <section className=" p-4 sm:p-8 md:p-10  mx-auto   bg-schoolColorMain">
        <div className="grid max-w-7xl mx-auto grid-cols-2 sm:grid-cols-4">
       {
        testimonials.map(({id, title, subTitles}) => (
            <div className="text-gray-50 text-center mt-2 sm:mt-0" key={id}>
                <h1 className='text-3xl sm:text-4xl md:text-5xl font-bold'> {title}</h1>
                <p className=' font-normal sm:font-medium md:font-semibold mt-2'> {subTitles}</p>

            </div>
        )
    )
       }

        </div>
    </section>
  )
}

export default Testimonials
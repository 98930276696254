import React from 'react'
import quranlogo from '../../../assets/e-learning/img/e-learning-message.png'
const MessageSection = () => {
  return (
    <div className='bg-colorBg'>
        <div className='flex flex-col-reverse my-20 sm: sm:flex sm:flex-row bg-colorBg items-center justify-between p-5 sm:p-10 max-w-7xl mx-auto gap-x-10'>
        <div className='flex flex-col  gap-y-5'>
            <h1 className='text-lg text-justify'>
            (O Beloved!) Read (commencing) with the Name of Allah, Who has created (everything). He created man from a hanging mass (clinging) like a leech (to the mother’s womb). Read, and your Lord is Most Generous, Who taught man (reading and writing) by the pen,
            </h1>
            <h1 className='text-lg font-bold '>
            Al-Quran (Al-Alaq-1-4)
            </h1>
            
        </div>
        <img src={quranlogo} alt="" />
    </div>
    </div>
  )
}
export default MessageSection
import React from "react";
import Heading from "../../../components/school/ui/Heading";
// import backgroundImg from "../../../assets/school/img/contact-backgroundGray.jpg";
import emailIcon from "../../../assets/school/img/contact-emailicon.png";
import locationIcon from "../../../assets/school/img/contact-locationIcon.png";
import phoneIcon from "../../../assets/school/img/phoneIcon.png";
import facebookIcon from "../../../assets/school/img/contact-facebookIcon.png";
import twitterIcon from "../../../assets/school/img/contact-skype.png";
import linkedinIcon from "../../../assets/school/img/contact-linkedinIcon.png";
import youtubeIcon from "../../../assets/school/img/contact-youtubeIcon.png";

import "../../../App.css";
const ContactSection = () => {
  const heading = "Contact us";
  return (
    <section className="flex flex-col items-center  justify-center  overflow-x-hidden    py-8 sm:py-10 px-4 sm:px-10 max-w-7xl mx-auto ">
      <div className=" flex flex-col justify-center   ">
        <div className="flex flex-col justify-start">
          <Heading heading={heading} />
          <h4 className="mt-4 md:mt-8 mb-4 text-xl sm:text-3xl md:text-4xl text-gray-800">
            Make a Query
          </h4>
          <div className="grid   grid-cols-1 md:grid-cols-2">
            <div className="about rounded-xl   contactsSectionBackground pt-8 pl-4 sm:pl-8">

              <div className="a">
                <h4 className="text-2xl sm:text-3xl md:text-4xl text-gray-800 font-semibold ">
                  Let’s get in touch with us
                </h4>

                <div className="icons mt-8 sm:mt-12 flex flex-col f">
                  <div className="email flex items-center gap-2 sm:gap-4 text-wrap">
                    <img
                      src={emailIcon}
                      className="w-5 h-5 sm:w-6 sm:h-6 flex items-center "
                      alt="email ICon"
                    />
                    <p className="text-gray-500 text-base sm:text-xl flex items-center break-all">
                      furqan31304@gmail.com
                    </p>
                  </div>
                  <div className="email flex gap-2 sm:gap-4 my-4 sm:my-8 items-center">
                    <img
                      src={locationIcon}
                      className="sm:w-6 sm:h-6 h-5 w-5 flex items-center"
                      alt="location ICon"
                    />
                    <p className="text-gray-500 text-base sm:text-xl flex items-center">
                      Rahimyar Khan
                    </p>
                  </div>
                  <div className="email flex gap-2 items-center">
                    <img
                      src={phoneIcon}
                      className="sm:w-6 sm:h-6 w-5 h-5 flex items-center "
                      alt="phone ICon"
                    />
                    <p className="text-gray-500 text-base sm:text-xl flex items-center">
                      +92 303 3899077
                    </p>
                  </div>
                </div>

                <div className="socialMedia mt-10 md:mt-60 pb-10 md:pb-2 ">
                  <h4 className="text-xl sm:text-2xl md:text-3xl text-gray-500 font-medium">Social Media</h4>
                  <div className="socialIcons flex gap-8 mt-4 flex-wrap">
                    <img
                      src={facebookIcon}
                      className="sm:w-6 sm:h-6 w-5 h-5 cursor-pointer"
                      alt="facebook icon"
                    />
                    <img
                      src={twitterIcon}
                      className="sm:w-6 sm:h-6 w-5 h-5 cursor-pointer"
                      alt="twitter icon"
                    />
                    <img
                      src={linkedinIcon}
                      className="sm:w-6 sm:h-6 w-5 h-5 cursor-pointer"
                      alt="linkedin icon"
                    />
                    <img
                      src={youtubeIcon}
                      className="sm:w-6 sm:h-6 w-5 h-5 cursor-pointer"
                      alt="youtube icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form mt-2 md:mt-0 bg-schoolColorMain rounded-xl px-4 py-8">
                <label htmlFor="name" className="text-white pl-2 text-base sm:text-xl mb-2 inline-block ">
                  Name
                </label>
                <input type="text" className="rounded-xl py-2 px-3 text-gray-700 w-full focus:outline-none " />
                <label htmlFor="name" className="text-white pl-2 text-base sm:text-xl mt-4 mb-2 inline-block ">
                  Phone Number
                </label>
                <input type="number" className="rounded-xl py-2 px-3 text-gray-700 w-full focus:outline-none " />
                <label htmlFor="name" className="text-white pl-2 text-base sm:text-xl  inline-block mt-4 mb-2 ">
                  City
                </label>
                <input type="text" className="rounded-xl py-2 px-3 text-gray-700 w-full focus:outline-none " />
                <label htmlFor="feedback" className="text-white pl-2 text-base sm:text-xl inline-block mt-4 mb-2 ">
                  Message
                </label>
                <textarea name="feedback"  id="feedback" className="rounded-xl w-full pl-8 py-4 pr-4 h-20 sm:h-40 focus:outline-none text-gray-700"></textarea>

                <button className="w-full mt-2 text-schoolColorMain py-2 text-base sm:text-xl flex justify-center rounded-xl text-center bg-white">
                   Send
                </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

import React from 'react'
import { useNavigate } from 'react-router-dom'

const EducationCard = ({ title,icon, description,link  }) => {
  const navigate=useNavigate()
  return (
    <div className='rounded-lg  bg-white p-5 flex flex-col gap-y-3 group hover:shadow cursor-pointer' onClick={()=>navigate(link)}>
 {icon}
<h1 className='font-semibold text-2xl border-b-2 border-transparent group-hover:border-colorPrimaryMain pb-5 w-auto '>{title}</h1>

<p className='text-gray-600'>
{ description }
</p>

    </div>
  )
}

export default EducationCard
import React from "react";
import aboutLogo from "../../../assets/e-learning/img/e-learning-about-logo.png";

const AboutSection = () => {
  return (
      <div className="aboutDetail  flex flex-col py-1 sm:py-2  md:py-4  justify-center p-10 max-w-7xl mx-auto gap-y-5">
        <div className="grid grid-cols-1 lg:grid-cols-3 mt-24 sm:mt-24 md:mt-24 text-start">
          <div className="text col-span-2 ">
            <h2 className=" mb-4 text-colorPrimaryMain  text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold md:font-semibold text-colorPrimary">
              {" "}
              About us
            </h2>
            <p className="text-gray-700">
              eLearning program is Minhaj-ul-Quran-led initiative that aims at
              providing the facility of Quranic Learning to those who find hard
              to arrange teacher in their area. The vast teaching experience and
              the in depth knowledge about translation, interpretation and
              grammatical rules of The Holy Quran that we teachers have, make
              our institution unique and matchless. Minhaj-ul-Quran
              International was founded by Shaykh-ul-Islam Dr. Muhammad
              Tahir-ul-Qadri. MQI is inclusive Islamic organization that
              represents the moderate version of Islam and it is endeavouring
              through its academic works for the promotion of interfaith harmony
              and global peace and security. The revamping of classical Islamic
              Knowledge through adopting modern educational strategies and
              methodologies is one of its prime cause of creation. In this
              regard, the eLearning programs have been launched by MQI with an
              aim to promote the Quranic Learning and the associated branches of
              science. We are committed to set standards for the most simple and
              successful method of teaching Quran and its Sciences. It is
              envisioned to be a beacon of light for the spread of Quran and
              Sunnah of Holy Prophet ﷺ. This program was launched in 2009 and
              proved tremendous success with respect of achieving its desired
              Students Learning Outcomes (SLOs) as well as the total number of
              participants.
            </p>
          </div>
          <div className="flex justify-center items-center  mt-4 ">
            <img src={aboutLogo} alt=" About Logo" />
          </div>
        </div>
      </div>
  );
};

export default AboutSection;

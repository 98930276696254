import React from 'react'
import offerBg from '../../../assets/e-learning/img/e-learning-offers-bg.png'
import OffersSlider from './OffersSlider'
import FeeSlider from './FeeSlider'
const FeeSection = () => {
  return (
        <div className='mx-auto max-w-7xl md:p-10 '>
        <div className='flex flex-col items-center justify-center p-5 md:p-10  mx-auto gap-y-5 '>
        <h1 className='text-xl font-semibold sm:text-4xl sm:font-medium text-center'>
        THE PERFECT PLAN FOR YOU
        </h1>
        <p className='font-normal text-lg text-center'>
        Fee structure presented below is exclusive of 3 days trial period and applies for monthly basis once clients are satisfied enough to continue classes.
        </p>
    </div>
    <FeeSlider/>
        </div>
        
    
  )
}

export default FeeSection
import React from 'react'
import educationImg from '../../assets/main/img/educationDegree.png'
import EducationCard from './EducationCard'
import WelfareSlider from './WelfareSlider'
const WelFare = () => {
  return (
   <div className='py-10'>
     <div className='flex flex-col  sm:flex sm:flex-row justify-between items-center '>
        <div>
            <h1 className='text-[#96BB7C] text-lg mb-2'>Al-SAFA</h1>
            <h1 className='text-colorPrimaryMain text-3xl sm:text-5xl font-bold mb-3'>WELFARE WORKS</h1>
            <p className='text-lg text-justify'>We Are Devoted to Empowering Lives, Nurturing Talents, and Enriching Communities, Creating Lasting Positive Change for Generations to Come</p>
        </div>
        <img className='w-1/2 sm:w-auto' src={educationImg} alt="" />
    </div>
   <WelfareSlider/>
   </div>
  )
}

export default WelFare
import React from 'react';
import event from "../../../assets/school/img/eventPic.jpg";
import { Link, NavLink } from 'react-router-dom';

const Events = ({bgColor, headingColor}) => {
    const admissionCard = [
        {
            id: 1,
            eventImg: event,
            title: "National STEAM Competition 2024",
            description: "STEAM is a teaching and learning programme introduced by 'THE EDUCATORS...."
        },
        {
            id: 2,
            eventImg: event,
            title: "National STEAM Competition 2024",
            description: "STEAM is a teaching and learning programme introduced by 'THE EDUCATORS...."
        },
        {
            id: 3,
            eventImg: event,
            title: "National STEAM Competition 2024",
            description: "STEAM is a teaching and learning programme introduced by 'THE EDUCATORS...."
        }

    ]
  return (

    <section className={`flex flex-col items-center  justify-center py-10  ${bgColor}`}>
       <div className=" flex flex-col justify-center  items-center p-10 max-w-7xl mx-auto gap-y-5 "> 
         <h1 className={`text-4xl md:text-5xl font-medium mb-10  ${headingColor}`}> News & Events </h1>
         <div className=" grid grid-cols-1 md:grid-cols-3  gap-4">
         {
           admissionCard.map(({id, eventImg, title, description})=> (
            <div className={`shadow-lg  ${bgColor}`} key={id}>
                <img src={eventImg} className={`w-full  rounded-t-lg md:rounded-t-xl`} alt="{eventImg}" />
                <div className='p-4 rounded-b-lg md:rounded-b-xl  bg-white'>
                  <h4 className='font-medium text-xl md:text-2xl my-2 text-schoolColorMain '>
                    {title}
                  </h4>
                  <p className='text-gray-600 text-base md:text-lg'>
                    {description}
                  </p>
                  <div className="flex justify-end">
                  <Link to="/school/aboutus/news-event" className='bg-schoolColorMain text-white rounded-lg text-base sm:text-lg md:text-xl  px-3 py-1.5 md:px-4 md:py-2 mt-2'>
                     Read more
                  </Link>
                  </div>
                </div>


            </div>
           ))
         }
         </div>
        </div>
    </section>

  )
}

export default Events
import React from 'react'
import CourseCard from './CourseCard'

const Course = () => {
  return (
    <div className='bg-colorBg pb-20'>
<div className='mx-auto max-w-7xl pt-36'>
        <div className='flex flex-col items-center justify-center  mx-auto gap-y-5'>
        <h1 className='text-xl font-semibold sm:text-4xl sm:font-medium text-center mb-10'>
        Course We Offer
        </h1>
    </div>
    <div className='flex gap-x-3 mx-2 mb-5'>
    <div class="relative  w-full">
  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
  <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" strokeLinecap="round" stroke-width="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
</svg>

  </div>
  <input type="text" id="input-group-1" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-12 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full" placeholder="Search courses"/>
</div>
<button className='bg-colorPrimary text-white px-10 py-2 rounded-lg'>
    Search

</button>

    </div>
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 '>
        <CourseCard/>
        <CourseCard/>
        <CourseCard/>
        <CourseCard/>
        <CourseCard/>
        <CourseCard/>
        <CourseCard/>
        <CourseCard/>
    </div>
    <div>
        
    </div>
        </div>
    </div>
    
  )
}

export default Course
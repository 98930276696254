import React from 'react';
import feeImg from "../../../assets/e-learning/img/e-learning-fe-contacts.png";

const Contact = () => {
  return (
    <div className='contactLayout flex flex-col items-center justify-center p-10 max-w-7xl mx-auto gap-y-5'>
        <div className="contactElements mt-14 sm:mt-16 md:mt-20 text-start">
            <div className="description">

            <h2 className='font-semibold flex justify-start text-2xl sm:text-3xl lg:text-4xl mb-2'> Contact Us</h2>
            <p className='text-gray-700 my-2'> Feel free from all over the world to call us or to send an email. We always love to hear from you. </p>
            <p className='font-semibold mb-1'> 
                <span> Phone number: </span>
                <span> +92 303-3899-077 </span>
            </p>
            <p className='font-semibold '> 
                <span> Email: </span>
                <span> alsafaelearning@gmail.com </span>
            </p>
            </div>
            <div className="img mt-4">
                <img src={feeImg} alt="female banner pic" />

            </div>

        </div>
    </div>
  )
}

export default Contact
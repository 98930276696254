import React from 'react'
import img from '../../../assets/e-learning/img/usama.jpg'
const Message = () => {
  return (
    <div className='flex w-full  rounded-lg  gap-0'>
        <div className=' bg-gradient-to-r from-[#2A00C6]  to-[#7D59FF] bg- w-full  rounded-s-xl p-10 text-white'>
            <div className=' flex justify-end item center opacity-25'>
            <svg width="75" height="61" viewBox="0 0 75 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M60.767 30.8025C69.1486 30.8025 74.7969 36.7383 74.7969 45.5333C74.7969 53.4477 68.3051 60.0412 59.5124 60.0412C49.8815 60.0412 42.7651 52.1268 42.7651 40.0378C42.7651 12.5603 62.4433 1.56929 74.7969 0.248413V12.3374C66.4206 13.8757 57.0032 22.4479 56.5815 31.906C57.0032 31.6886 58.6742 30.8025 60.767 30.8025ZM18.0632 30.8025C26.4341 30.8025 32.0878 36.7383 32.0878 45.5333C32.0878 53.4477 25.596 60.0412 16.8032 60.0412C7.17232 60.0412 0.0559082 52.1268 0.0559082 40.0378C0.0559082 12.5603 19.7341 1.56929 32.0878 0.248413V12.3374C23.7114 13.8757 14.2941 22.4479 13.8723 31.906C14.2941 31.6886 15.9651 30.8025 18.0632 30.8025Z" fill="white"/>
</svg>



            </div>
            <h1 className='text-2xl mt-2'>
Lorem ipsum dolor sit amet consectetur. Consequat auctor consectetur nunc vitae dolor blandit. Elit enim massa etiam neque laoreet lorem sed. 
</h1>
<h1 className='mt-5 text-2xl'>
            Muhammad Usama
        </h1>
        <h1 className='opacity-50 mt-1'>
            Founder Al-Safa Foundation
        </h1>
        </div>
        
        <img className='m-0 w-fit  rounded-e-xl object-contain h-[440px]' src={img} alt="" />   
    </div>
  )
}

export default Message
import React from 'react'

const Heading = ({heading}) => {
  return (
    <h1 className='bg-schoolColorMain text-3xl sm:text-4xl   text-white font-semibold sm:font-bold  rounded-lg  text-left p-2 sm:p-4 md:p-5'>
        { heading }
    </h1>
  )
}

export default Heading
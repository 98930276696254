import React from 'react'
import Navbar from '../../../components/e-learning/ui/Navbar'
import heroImg from '../../../assets/e-learning/img/e-learning-herosection-bg.jpg'
import { useNavigate } from 'react-router-dom';
const HeroSection = () => {
  const navigate=useNavigate();
  return (
    <div style={{'backgroundImage':`url(${heroImg})`}}  className='h-screen flex flex-col'>
        <div className='flex flex-col justify-center items-center text-white p-10 h-screen '>
          <div className='text-center bg-black p-5 bg-opacity-75 sm:w-1/2 flex flex-col items-center justify-center gap-y-1 rounded-lg'>
          <h1>Al-Safa foundation offers</h1>
<h1 className='text-xl sm:text-2xl md:text-4xl lg:text-6xl  font-bold text-colorPrimary mb-10'>Online Quran Learning</h1>
<button className='bg-colorPrimary text-white px-10 py-2 rounded-lg bg-opacity-100' onClick={()=>navigate('/elearning/registration')}>Join Now</button>
          </div>
        </div>
        
    </div>
  )
}

export default HeroSection
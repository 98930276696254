import React from 'react'
import Vision from './Vision'
import Events from '../../../components/school/ui/Events'


const AboutUs = () => {
  const background = "bg-gray-100";
  const heading = " text-gray-800";
  return (
    <section className=' pt-16 md:pt-28 '>
      <Vision/>
      <Events bgColor={background} headingColor={heading}/>

    </section>
  )
}

export default AboutUs
import React from 'react'
import News from './News'
import Herosection from './Herosection'
import Education from './Education'
import WelFare from './WelFare'
import Footer from '../../components/e-learning/ui/Footer'

const Main = () => {
  return (
    <div className='bg-colorBgMain '>
    <div className='mx-auto max-w-7xl pt-2 px-2 md:px-5 2xl:px-0'>
            <News/>
            <Herosection/>
            </div>
            <div className='bg-white flex flex-col items-center py-4 hover:text-colorPrimaryMain cursor-pointer'>
                <h1 className='text-xl'>
                What’s going on in Al-Safa
                </h1>
                <svg class="w-8 h-8 text-colorPrimaryMains dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7"/>
</svg>


                

            </div>
            <div className='mx-auto max-w-7xl pt-2 px-2 md:px-5 2xl:px-0'>
            {/* <News/> */}
            <Education/>
         
            </div>
           <hr />
            <div className='mx-auto max-w-7xl pt-2 px-2 md:px-5 2xl:px-0'>
            <WelFare/>
            </div>
            <Footer/>
          
            
      

    </div>
  )
}

export default Main
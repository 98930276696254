import React from 'react'
import Detail from "./Detail.jsx";
import Banner from './Banner.jsx';
import Foculty from './Foculty.jsx';
import Images from './Images.jsx';


const ElearningAboutus = () => {
  return (
    <div className=''>
        <Detail/>
        <Banner/>
        <Foculty/>
        <Images/>
    </div>
  )
}

export default ElearningAboutus
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import starImg from "../../../assets/school/img/star.png";

import carouselImg from "../../../assets/school/img/home-carousel-slide.jpg";
import logo from  "../../../assets/school/svg/logo.png";

const Carousel = () => {
  var settings = {
    infinite: true,
    speed: 500,
    arrows:false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const carouselImgs = [ 
    {
      id: 1,
      slide: carouselImg
    },
    {
      id: 2,
      slide: carouselImg
    },
  ]

  

  return (
    <div className="mt-4">
      <Slider {...settings} className="transition-all ease-in-out">
        {
          carouselImgs.map((items)=>(
          <div className="relative " key={items.id}>
          <div className="">
            <img src={items.slide} className="w-full  h-[50vh] sm:h-[70vh] md:h-[85vh]" alt="carousel img" />
          </div>
          <div className="carouselLogo absolute top-4 left-6 bg-gray-300 w-20 h-24 flex justify-center items-center rounded-xl">
             <img src={logo} alt="carousel Logo" />
          </div>
          <div className="heading absolute bottom-12 right-3 sm:right-4 md:right-8">
            <h1 className=" text-4xl sm:text-6xl md:text-7xl font-bold  sm:font-bold md:font-extrabold text-white">
               SHINE
               <span className="  text-schoolColorMain flex">

                 LIKE 
                 <img src={starImg}  className="sm:mt-4 md:mt-6 ml-6 md:ml-12 -mb-4 w-12 h-12 sm:w-20 sm:h-20 md:w-28 md:h-28" alt="star icon" />
               </span>
               STARS

            </h1>

          </div>
          </div>
          ))
        }


      </Slider>

    </div>
);
};


export default Carousel
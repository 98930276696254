import React from 'react';
import resultImg from "../../../assets/school/img/admission-result.png";

const Result = () => {
    const result = [" Quizess", " Monthly Test", "Mid-year", "Final-year"]
  return (
    <div className="result w-full px-4 rounded-xl text-center   bg-gray-200 py-8">
       <h2 className='text-gray-600 font-bold text-4xl '>Results</h2>
       <p className=" text-gray-600 text-xl mt-4 md:text-2xl  text-start" >
          Examination results are compiled, recorded and maintained in respective student’s files. Results are usually put up on the Class Examination Result Sheet and displayed on the notice board; copies of which are authenticated by the School Examination Committee and communicated to the Controller Examinations. Results are obtained from the following:
       </p>

       <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
         {
            result.map((item,index)=>(
                <div className="bg-white rounded-xl px-4 py-3 flex gap-2" key={index}>
                    <img src={resultImg} className='w-10 h-12' alt="result img" />
                    <h4 className=' text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold flex justify-center items-center w-fit mx-auto text-schoolColorMain'> {item}</h4>
                </div>
            ))
         }
       </div>

       <p className='border mt-4 p-3 border-gray-400 text-gray-500 text-base sm:text-lg  rounded-xl'>
          The result is recorded in a Progress Report and then communicated to respective students and parents.
       </p>

    </div>
  )
}

export default Result
import React from "react";
import Heading from "../../../components/school/ui/Heading";
import event from "../../../assets/school/img/eventPic.jpg";
import reciteQuran from "../../../assets/school/img/gallery-recite-quran-left.jpg";
import quran from "../../../assets/school/img/gallery-quran-pic.jpg";
import teacher from "../../../assets/school/img/gallery-teacher.jpg";
import chairMan from "../../../assets/school/img/about-chairMan.png";
import lady from "../../../assets/school/img/gallery-femailPic.jpg";
import hades from "../../../assets/school/img/gallery-hadesBook.jpg";
import children from "../../../assets/school/img/gallery-children.jpg";
import reciteQuranR from "../../../assets/school/img/gallery-reciteQuran.jpg";
import quranClass from "../../../assets/school/img/gallery-quran-class.jpg";

const GalleryPics = () => {
  const heading = "Gallery";
  return (
    <section className="flex flex-col items-center  justify-center  p-4 sm:p-8 md:p-10 max-w-7xl mx-auto gap-y-5">
      <div className=" flex flex-col justify-center   ">
        <div className="flex flex-col justify-start">
          <Heading heading={heading} />
          <div className="my-4 grid gap-1 sm:gap-1 sm:gap-1 sm:gap-2">
            <div className="grid grid-cols-3 gap-1 sm:gap-1 sm:gap-2">
              <div className="col-span-2">
                <img
                  src={event}
                  alt="child events"
                  className="w-full rounded-xl"
                />
              </div>
              <div>
                <img
                  src={reciteQuran}
                  className="w-full h-full"
                  alt="recite quran lady"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-1 sm:gap-1 sm:gap-2">
              <div className="">
                <img src={quran} className="w-full h-full" alt=" quran " />
              </div>
              <div className="col-span-2 grid gap-1 sm:gap-1 sm:gap-2">
                <img
                  src={teacher}
                  className="w-full "
                  alt=" quran teacher "
                />
                <div className="grid grid-cols-2 gap-1 sm:gap-1 sm:gap-2">
                  <div className="">
                    <img src={chairMan} className=" min-w-full" alt=" chair man pic " />
                  </div>
                  <div className="">
                    <img src={lady} className="min-w-full h-full" alt=" lady " />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-1 sm:gap-2">
              <div className="col-span-2 grid gap-1 sm:gap-2">
                <img
                  src={hades}
                  className="w-full "
                  alt=" hades book "
                />
                <div className="grid grid-cols-2 gap-1 sm:gap-1 sm:gap-2">
                  <div className="">
                    <img src={reciteQuranR} className=" min-w-full" alt=" recite quran " />
                  </div>
                  <div className="">
                    <img src={quranClass} className="min-w-full h-full" alt=" Quran majeed Classes " />
                  </div>
                </div>
              </div>
              <div className="">
                <img src={children} className="w-full h-full" alt=" children " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalleryPics;

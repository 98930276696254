import React from 'react'
import SecondCarousel from './SecondCarousel'
import HeroSlider from './HeroSlider'

const QuranHome = () => {
  return (
    <div>
        {/* <Carousel/> */}
        <HeroSlider/>

        <SecondCarousel/>
        {/* import pic1 from "../../../assets/e-learning/img/elearning-carousel-img1.jpg"; */}

    </div>
  )
}

export default QuranHome
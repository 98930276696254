import React from "react";
import banner from "../../../assets/tahfeezul -quran/img/admission-banner-page-ui.jpg";

const AdmissionCritera = () => {
  return (
    <div className="admissionLayout flex flex-col items-center  justify-center p-10 max-w-7xl mx-auto gap-y-5">
      <div className="  flex flex-col   items-center  mt-14 sm:mt-16 md:mt-20 ">
        <h2 className="mr-auto text-xl mt-2 md:mt-0 mb-0 md:mb-4 sm:text-2xl md:text-3xl lg:text-4xl font-bold  text-blue-600  ">
          Admission Criteria
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 rounded-xl pt-0 md:pt-4">
          <div className="px-3 py-2 md:py-4 flex-col items-start">
            <div className="eligibilityCriteria">
              <h4 className="py-2  font-semibold ">
                Eligibility for admission to memorize the Quran:
              </h4>
              <div className=" bg-gray-100 px-4 py-2  rounded-lg  text-gray-700 w-fit">
                <li>
                  <span>5th class pass</span>
                </li>
                <li>
                  <span>Age 11 years</span>
                </li>
                <li>
                  <span>Recitation of Holy Quran</span>
                </li>
              </div>
            </div>

            <div className="requiredDocument">
              <h4 className="py-2 font-semibold mt-2">Required Documents:</h4>
              <div className=" bg-gray-100 px-4 py-2 w-fit  rounded-lg  text-gray-700">
                <li> School Certificate (original) </li>
                <li>Form (B) / Birth Certificate Copy (NADRA) </li>
                <li> Photos (4 pieces)</li>
                <li> Father / guardian ID Card Copy</li>
              </div>
            </div>
            <div className="hostelSupplies">
              <h4 className="py-2 font-semibold mt-2">Hostel Supplies:</h4>
              <div className="  mt-2   rounded-lg  text-gray-700 flex-col ">
                <span className="bg-gray-100 rounded-xl px-3 p-2 m-1 block w-fit">
                  Beds (per season) + Pillow cover two sheet
                </span>
                <span className="bg-gray-100 rounded-xl px-3 p-2 inline-block m-1">
                  Towels{" "}
                </span>
                <span className="bg-gray-100 rounded-xl px-3 p-2 inline-block m-1">
                  Soap{" "}
                </span>
                <span className="bg-gray-100 rounded-xl px-3 p-2 inline-block m-1">
                  Paste{" "}
                </span>
                <span className="bg-gray-100 rounded-xl px-3 p-2 m-1 inline-block">
                  Tooth Brush{" "}
                </span>
                <span className="bg-gray-100 rounded-xl px-3 p-2 inline-block m-1">
                  Shoe Polish{" "}
                </span>
                <span className="bg-gray-100 rounded-xl px-3 p-2 inline-block m-1">
                  Sports kit{" "}
                </span>
                <span className="bg-gray-100 rounded-xl px-3 p-2 m-1 inline-block">
                  Nail cutter{" "}
                </span>
                <span className="bg-gray-100 rounded-xl px-3 p-2 m-1 inline-block">
                  Two locks smaller size (for Cupboard){" "}
                </span>
              </div>
            </div>
          </div>
          <div className="admissionBanner flex justify-center md:justify-end">
              <img src={banner} className="rounded-lg " alt="Admission Banner img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionCritera;

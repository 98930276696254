import chairMan from "../../../assets/school/img/about-chairMan.png";

const ChairPerson = () => {
  return (
    <section className='py-4'>
        <h2 className="text-gray-800 text-2xl sm:text-4xl font-semibold my-4">
            Chairperson’s Message
        </h2>
        <div className="bg-schoolColorMain px-4 py-6 rounded-lg">

            <div className=" flex grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-2">
              <img src={chairMan} className=" w-76 h-76 flex justify-center" alt="chair man Person pic" />
              <p className="text-white lg:col-span-2 px-4 text-base md:text-lg  mt-4 line-clamp-2">
                "I feel privileged to be part of an organisation that has a long tradition of dedicated service for the nation. First and foremost, we are committed to serving the community by providing quality education of an international standard within our cultural framework in our schools across the country. We are one of the  a
                 schools across the country. We are one of the  a
               students and thousands of caring and dedicated staff members.Our schools offer the finest facilities, which include state-of-the art computer laboratories, fully equipped science laboratories, well stocked libraries, teaching aids and other essentials necessary for an environment that is conducive to teaching and learning.
             </p>
            </div>
            <p className="text-white col-span-2  text-base md:text-lg  my-2">
            It has been 48 years since the Beaconhouse School System came into being, and over these wonderful years we have grown from strength to strength and made significant contributions towards the quality and standard of education in Pakistan. We believe that every child has a right to education. We endeavour to and will continue to extend our reach so that meaningful, accessible and affordable education is available to all children. It is with this firm belief in the right for quality, accessible and affordable education that The Educators came into existence. We hope to forge a strong alliance with all those who believe in and share our vision by inviting them to join our cause."
            </p>

        </div>

    </section>
  )
}

export default ChairPerson
import React, { Component } from "react";
import ReactDOM from "react-dom";
import pic1 from "../../../assets/e-learning/img/elearning-carouse-profile.jpg";
import dQoutes from "../../../assets/e-learning/svg/e-learning-home-carosel-doubleQuotes.svg";
import FeeSlider from "../../elearning/home/FeeSlider";
import FounderSlider from "./FounderSlider";

const SecondCarousel = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

  return (
  

    <div className='mx-auto max-w-7xl md:p-10 '>
    <div className='flex flex-col items-center justify-center p-5 md:p-10  mx-auto gap-y-5 '>
    <h1 className='text-xl font-semibold sm:text-4xl sm:font-medium text-center'>
    Founder and Management Messages
    </h1>
    <p className='font-normal text-lg text-center'>
   Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint et iusto rem culpa, ipsa repellendus, repellat magni commodi beatae exercitationem natus provident fuga placeat officiis iure perferendis voluptatibus ullam minima?
    </p>
</div>
<FounderSlider/>
    </div>
  );
};

export default SecondCarousel;

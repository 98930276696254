import React, { useEffect } from 'react'
import Navbar from '../ui/Navbar'
import Footer from '../ui/Footer'

const ElearningLayout = ({children}) => {
  useEffect(() => {
    // Scroll to the top of the page whenever `children` changes
    window.scrollTo(0, 0);
  }, [children]);
  return (
    <>
    <Navbar/>
    {children}
      <Footer/></>
  )
}

export default ElearningLayout
import React from 'react'

const BenefitCard = ({bg,color,heading,para,icon}) => {
  return (
    <div className={`p-5 rounded-lg`} style={{backgroundColor:bg}}>
        <div className='flex text-[#692BC7] mb-3 justify-between ' style={{color:color}}>
        <h1 className='text-2xl font-medium'>{heading}</h1>
        <div className='w-32 flex justify-end'>
        {icon
       }
        </div>
       
        </div>
        <p className='text-justify ' style={{color:color}}>
        {para}
        </p>
    </div>
  )
}

export default BenefitCard
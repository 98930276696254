import React from 'react';
import openBook from "../../../assets/school/svg/book.svg";
import closedBook from "../../../assets/school/svg/closedBook.svg";
import mosque from "../../../assets/school/svg/mosque.svg";

const Admission = () => {
    const admissionCard = [
        {
            id: 1,
            icon: mosque,
            title: "Admission Procedure",
            description: "Daily Discussion about the history of Islam"
        },
        {
            id: 2,
            icon: closedBook,
            title: "Academics",
            description: "Nazra and  Hifz Quran  according to the classes"
        },
        {
            id: 3,
            icon: openBook,
            title: "Examinations",
            description: "Courses such as Nazra,  Tafseer-e-Quran and other much more"
        }
    ]
  return (
    <section className='flex flex-col items-center  justify-center py-4 sm:py-8 md:py-12 bg-gray-100'>
       <div className=" flex flex-col justify-center  items-center p-4 sm:px-8  md:p-10 max-w-7xl mx-auto gap-y-5 "> 
         <h1 className='text-3xl sm:text-4xl md:text-5xl text-gray-800 font-medium mb-4 md:mb-10'> Admissions </h1>
         <div className=" grid grid-cols-1 sm:grid-cols-3  gap-4">
         {
           admissionCard.map(({id, icon, title, description})=> (
            <div className="shadow-lg px-4 py-6 bg-white rounded-sm" key={id}>
                <img src={icon} alt="{icon}" />
                <h4 className='font-semibold text-gray-800 sm:text-lg md:text-xl my-2'>
                    {title}
                </h4>
                <span className='w-12 border-b-2 block mb-3 border-schoolColorMain'> {" "} </span>
                <p className='text-gray-600  text-base md:text-xl'>
                    {description}
                </p>


            </div>
           ))    
         }
         </div>
        </div>
    </section>
  )
}

export default Admission
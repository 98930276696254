import React from "react";
import Heading from "../../../components/school/ui/Heading";
import { Link } from "react-router-dom";
import Procedure from "./Procedure";
import Academics from "./Academics";
import Examinations from "./Examinations";
import Result from "./Result";

const MainPage = () => {
  const [activeSection, setActiveSection] = React.useState("section1");
  const heading = "Admissions";

  // Function to dynamically assign classes to links
  const getLinkClasses = (isActive) =>
    `px-3 py-2 text-sm font-medium ${
      isActive
        ? "text-black border-b border-black"
        : "text-gray-400 hover:text-gray-600"
    }`;

  return (
    <section className="flex flex-col items-center  justify-center p-10 max-w-7xl mx-auto gap-y-5">
      <div className="flex flex-col justify-center">
        <div className="flex flex-col justify-start ">
          <Heading heading={heading} />

          <div className="flex gap-4 bg-gray-100 w-full px-4 py-2 my-2 rounded-xl">
            <Link
              to="#"
              className={getLinkClasses(activeSection === "section1")}
              onClick={() => setActiveSection("section1")}
            >
              Admission Procedure
            </Link>
            <Link
              to="#"
              className={getLinkClasses(activeSection === "section2")}
              onClick={() => setActiveSection("section2")}
            >
              Academics
            </Link>
            <Link
              to="#"
              className={getLinkClasses(activeSection === "section3")}
              onClick={() => setActiveSection("section3")}
            >
              Examinations
            </Link>
          </div>
        </div>

        {/* Conditionally render each section based on the activeSection state */}
        {activeSection === "section1" && <Procedure />}
        {activeSection === "section2" && <Academics />}
        {activeSection === "section3" &&
         <>
           <Examinations />
           <Result/>
        </>
        }
      </div>
    </section>
  );
};

export default MainPage;

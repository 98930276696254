import React from "react";
import banner from "../../../assets/e-learning/img/e-learning-about-banner.png";
import checkedIcon from "../../../assets/e-learning/svg/e-learning-about-checkedIcon.svg";

const Banner = () => {
  const bannerData = [
    {
      id: 1,
      BannerText: "No Traveling/Driving",
    },
    {
      id: 2,
      BannerText: "Well Qualified Tutors",
    },
    {
      id: 3,
      BannerText: "Affordable Fee",
    },
    {
      id: 4,
      BannerText: "Convenient learning Environment",
    },
    {
      id: 5,
      BannerText: "Learn While You Earn",
    },
    {
      id: 6,
      BannerText: "Up to 250,000 tracked visits",
    },
    {
      id: 7,
      BannerText: "Normal support",
    },
    {
      id: 8,
      BannerText: "Up to 3 team members",
    },
  ];
  return (
    <div className="w-screen banner relative">
      <div className="img w-screen overflow-hidden">
        <img
          src={banner}
          className=" w-full h-fit"
          alt="Banner img"
        />
      </div>
      <div className="block px-10  text-gray-800   sm:absolute my-10 md:mt- lg:mt-32 xl:mt-48 lg:my-20   top-0 right-4 sm:right-16 md:right:28 lg:right-40  xl:right-60   ">
        <h4 className="lg:text-4xl md:text-3xl text-2xl text-gray-700 sm:text-white my-2 sm:mb-8 font-semibold">
          {" "}
          Why you should join us?{" "}
        </h4>
        <ul className="" >
          {bannerData.map(({id, BannerText}) => (
            <li className="flex  items-center sm:mb-1 md:mb-1.5 lg:mb-2.5  xl:mb-3" key={id}>
              <span className="mr-2">
                <img src={checkedIcon} alt="checked Icon " />
              </span>
              <span className="text-gray-700 my-1 sm:my-0 sm:text-gray-200 text-base md:text-lg xl:text-2xl">{BannerText}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Banner;

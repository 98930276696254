import React from 'react'
import bg from '../../assets/main/img/welfarebg.png'
const WelfareCard = () => {
  return (
    <div style={{'backgroundImage':`url(${bg})`, 'backgroundRepeat':'no-repeat','backgroundSize':'cover'}}  className='relative  rounded-3xl w-full h-96 mb-5 '>
    {/* <img className='w-full rounded-3xl ' src={bg} alt="" /> */}
    <div className='bg-purple-800 text-2xl rounded-3xl  md:w-2/3 lg:w-1/2 bg-opacity-30 absolute top-0 p-14 rounded-s-3xl h-full flex flex-col gap-y-5 items-start justify-center '>
        <h1 className='text-white md:text-3xl lg:text-4xl flex items-center'>
        Al-SAFA Foundation dispatches relief goods for flood affectees (Flood Relief 2022)
        </h1>     
        <button className='bg-white px-8 py-2 rounded-lg text-colorPrimaryMain '>
            View More
        </button> 
    </div>
   </div>
   
  )
}

export default WelfareCard
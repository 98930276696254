import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OfferCard from "./OfferCard";
import { coursesData } from "./CoursesData";

function OffersSlider() {
  let sliderRef = useRef(null);


  const settings = {
    dots: true,
    arrows:false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
               
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                
            }
        },
    ]
  };



  return (

    <div className="slider-container sm:px-10">
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        {
          coursesData.map((item)=><div>
          <OfferCard title={item?.title} description={item?.description} />
          </div>)
        }
      </Slider>
     
    </div>
  );
}
export default OffersSlider;

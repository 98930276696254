import React from "react";

const Facilities = () => {
  return (
    <div className="facilitesLayout flex flex-col  justify-center p-10 max-w-7xl mx-auto gap-y-5">
      <div className="facilitiesElements  mt-14 sm:mt-16 md:mt-20 text-start">
      <h2 className="text-xl mb-4 sm:text-2xl md:text-3xl lg:text-4xl font-bold  text-colorPrimaryMain  ">
       Facilities
      </h2>
        <p className="text-gray-600  ">
          Tehfeez is housed in a state of the art triple storied building
          comprising of basement, ground, first and second. Facilities made
          available to students in this complex are as unde
        </p>

        <ul className="text-gray-500 mt-4 list-disc pl-6">
          <li>
            <p className="mb-2 py-1">
              Spacious, well lighted & airy classrooms
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Computer lab with latest PCs and multimedia projector.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Well equipped, spacious and purpose built laboratories for
              Physics, Chemistry & Biology.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Well stocked library with thousands of books. Different
              newspapers, magazines and chronicles are also made available to
              students at the library. Two PCs with internet connectivity and
              on-line libraries have also been provided.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Separate library for junior level students.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Causality Centre for immediate medical help, if required, and also
              routine medical checkup.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Qualified doctor and medical assistant available round the clock.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Sports store with a good stock of sports gear/equipment.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Dining Hall with capacity of 300 students at a time.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Boarding facility for over 500 students.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">In-house laundry & barber shop. </p>
          </li>
          <li>
            <p className="mb-2 py-1">Uniform & stationery shop. </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              High powered Generator to take the load of whole complex.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              A grand spacious mosque with capacity of 2000 at a time.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Foolproof security system ensured though our own guards and Punjab
              police as well.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Ambulance service in case a patient needs to be evacuated to a
              hospital.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Spacious and well set canteen where students are provided with
              hygienic snacks.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Fire Exits have been planned as per international standards.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Smoke detector and firefighting equipment has been installed-at
              par with international standards-to avoid any major damage to life
              and property in case of fire on each floor.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Close circuit cameras have been installed throughout the building
              to ensure security of students at all times.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Round the clock supervision of students by house staff, house
              masters, vice principal and principal.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Neat & tidy washrooms and bathrooms with modern facilities and
              ample staff to ensure cleanliness at all times.{" "}
            </p>
          </li>
          <li>
            <p className="mb-2 py-1">
              Complete record of students’ progress is maintained and shared
              with parents or guardians at regular intervals-at Parent Teacher
              Meetings.{" "}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Facilities;

import React, { useState } from 'react';
import logo from '../../../assets/e-learning/svg/logo.svg';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const getLinkClasses = (isActive) =>
    `px-3 py-2 text-sm font-medium ${
      isActive
        ? 'text-colorPrimaryMain border-b-2 border-colorPrimaryMain'
        : 'text-gray-600 hover:text-colorPrimaryMain hover:border-b-2 border-colorPrimaryMain'
    }`;

  return (
    <nav className="lg:w-full p-2 bg-white fixed w-full ease-in-out duration-500 bg-clip-padding z-20 shadow-lg">
      <div className="mx-auto px-2 max-w-7xl lg:px-6 lg:px-8 bg-white">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen ? 'true' : 'false'}
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isMobileMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
              <svg
                className={`${isMobileMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <h1 className="text-colorPrimaryMain font-bold text-2xl">
                TAHFIZ-UL-QURAN
              </h1>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
            <div className="hidden lg:ml-6 lg:block">
              <div className="flex space-x-4">
                <NavLink
                  to="/quran/home"
                  className={({ isActive }) => getLinkClasses(isActive)}
                  aria-current="page"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/quran/admission"
                  className={({ isActive }) => getLinkClasses(isActive)}
                >
                  Admission
                </NavLink>
                <NavLink
                  to="/quran/fee"
                  className={({ isActive }) => getLinkClasses(isActive)}
                >
                  Fee
                </NavLink>
                <NavLink
                  to="/quran/facilities"
                  className={({ isActive }) => getLinkClasses(isActive)}
                >
                  Facilities
                </NavLink>
                <NavLink
                  to="/quran/syllabus"
                  className={({ isActive }) => getLinkClasses(isActive)}
                >
                  Syllabus
                </NavLink>
                <NavLink
                  to="/quran/uniform"
                  className={({ isActive }) => getLinkClasses(isActive)}
                >
                  Uniform
                </NavLink>
                <NavLink
                  to="/quran/aboutus"
                  className={({ isActive }) => getLinkClasses(isActive)}
                >
                  About us
                </NavLink>

                <NavLink
                  to="/"
                  className="rounded-md bg-colorPrimaryMain px-4 py-2 text-sm font-medium text-white flex justify-between items-center"
                >
                  Visit Foundation
                  <svg
                    className="w-6 h-6 text-white dark:text-white ml-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          isMobileMenuOpen ? 'block' : 'hidden'
        } lg:hidden bg-white mt-2 rounded-lg`}
        id="mobile-menu"
      >
        <div className="space-y-1 px-2 pb-3 pt-2">
          <NavLink
            to="/quran/home"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            Home
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            Admission
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            Fee
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            Facitilities
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            Syllabus
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            Uniform
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive }) => getLinkClasses(isActive)}
          >
            About us
          </NavLink>
          <NavLink
            to="/"
            className="rounded-md bg-colorPrimaryMain px-4 py-2 text-sm font-medium text-white flex justify-between items-center"
            aria-current="page"
          >
            Visit Foundation
            <svg
              className="w-6 h-6 text-white dark:text-white ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

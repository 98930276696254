import React from 'react';
import focultyPic from "../../../assets/e-learning/img/e-learning-about-foculty.jpg";

const Foculty = () => {
  return (
    <div className="aboutDetail flex flex-col  justify-center p-10 max-w-7xl mx-auto gap-y-5">
        <div className="py-8  px-auto grid md:grid-cols-2 grid-cols-1 ">
            <div className="focultyDescription">
                <h4 className='md:text-3xl lg:text-4xl sm:text-2xl text-xl my-4 font-semibold'> Teaching Foculty </h4>
                <p className="text text-gray-700 ">
                Teaching faculty is the building block of any institution that could support and sustain the organization and it can also cause the downfall of any institution. We are lucky that we have well qualified and determined member of staff. All the members of staff are university graduate and well entrenched in Quranic sciences. They have been provided training how to effectively educate the students as per their mental capabilities. They are all individuals of high noble character and have excellent proficiency over English, Arabic and Urdu and other languages.
                </p>
            </div>
            <div className="focultyPic flex justify-center items-center sm:mt-6 mt-4 ">
                <img src={focultyPic} alt="Foculty member" />
            </div>

        </div>
    </div>
  )
}

export default Foculty
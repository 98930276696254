import React from 'react'
import BenefitCard from './BenefitCard'
import { IndividualLearningSessions,AdjustableTimings,AnyWhereAnyDevice,FemaleQuranTeachers,InteractiveClasses,NoLanguageBarrier } from '../../../assets/e-learning/svg/svgs'


const Benefits = () => {
    const data=[
        {
            icon:IndividualLearningSessions,
            heading:'Individual Learning Sessions',
            para:'Each and every student will be given individual attention that is key to the best learning and effective implantation of knowledge.',
            bg:'#F6F0FF',
            color:"#692BC7"
        },
        {
            icon:AdjustableTimings,
            heading:'Adjustable Timings',
            para:'We have adjustable and flexible timetable that will prove blessing for sisters who find very hard to attend classes due to their routine work.',
            bg:'#FFF9E9',
            color:"#D8B047"
        },
        {
            icon:AnyWhereAnyDevice,
            heading:'Anywhere, Any Device',
            para:'Keeping in view the culture and tradition, we have arranged experienced, qualified and learned female teachers in order to educate the female students.',
            bg:'#ECFFFE',
            color:"#54B5B0",
        },
        {
            icon:FemaleQuranTeachers,
            heading:'Female Quran Teachers',
            para:'FemaleTutor.com is proving a platform where different online tools such as video calling, screen sharing, multi-way audio calling and live video streaming are being employed to make the sessions more informative and effective',
            bg:'#F0F3FF',
            color:"#6072BA"
        },
        {
            icon:InteractiveClasses,
            heading:'Interactive Classes',
            para:'Language barrier is no more barrier: femaletutor.com has such tutors who can fluently communicate in English, Urdu, and Arabic. This is proving very helpful for students and making the learning more effective and penetrating.',
             bg:'#FFECEC',
            color:"#CB5656"

        },
        {
            icon:NoLanguageBarrier,
            heading:'No Language Barrier',
            para:'Language barrier is no more barrier: femaletutor.com has such tutors who can fluently communicate in English, Urdu, and Arabic. This is proving very helpful for students and making the learning more effective and penetrating.',
            
            bg:'#E9FFEA',
            color:"#59B65E",
        },
    ]
  return (
    <div className='flex flex-col items-center justify-center p-10 max-w-7xl mx-auto gap-y-5'>
        <h1 className='text-xl font-semibold sm:text-4xl sm:font-medium text-center'>
        Why you should join us?
        </h1>
        <p className='text-lg text-center '>
        Joining our institute gives you a great chance to learn free from any sectarianism, extremism about Quranic education, ethics and character building with Quran and Sunnah. We focus on helping you grow both personally and spiritually by teaching not just knowledge, but also strong moral values and ethical behavior. Our programs are designed to help you find a deeper purpose in life and build inner peace, encouraging you to live with kindness, honesty, and wisdom. When you join us, you become part of a caring community that supports your spiritual growth and helps you connect more deeply with your faith.
        </p>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 sm:grid-cols-2 mx-auto gap-4 mt-10'>
            {
                data?.map((item)=><BenefitCard icon={item.icon} para={item.para} color={item.color} bg={item.bg} heading={item.heading}/>)
            }
        </div>
    </div>
  )
}
export default Benefits
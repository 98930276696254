import React from 'react'
import AboutSection from './AboutSection'

const QuranAboutPage = () => {
  return (
    <div>
       <AboutSection/>
    </div>
  )
}

export default QuranAboutPage
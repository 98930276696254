import React from 'react'
import UniformInstruction from './UniformInstruction'

const Uniform = () => {
  return (
    <div className=''>
        <UniformInstruction/>
    </div>
  )
}

export default Uniform
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OfferCard from "./OfferCard";
import FeeCard from "../../../components/e-learning/ui/FeeCard";
import FeeCardMini from "../../../components/e-learning/ui/FeeCardMini";

function FeeSlider() {
  let sliderRef = useRef(null);
  const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
               
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                
            }
        },
    ]
  };
  return (
    <>
       <div className="slider-container md:px-10 sm:hidden">
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        <FeeCardMini type={`Basic`}/>
        <FeeCardMini type={`Intermediate`}/>
        <FeeCardMini type={`Advance`}/>

        
       
        
      </Slider>
     
    </div>
    <div className="slider-container md:px-10 hidden sm:block">
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        
        <FeeCard type={`Basic`}/>
        <FeeCard type={`Intermediate`}/>
        <FeeCard type={`Advance`}/>
      </Slider>
     
    </div></>
 
  );
}
export default FeeSlider;

export const coursesData= [
      {
        "title": "Tajweed Course",
        "description": "A Tajweed course focuses on the correct pronunciation and articulation of Arabic letters and sounds in the recitation of the Quran. Tajweed, meaning 'improvement' or 'enhancement,' ensures that the Quran is read with precise phonetic accuracy, respecting its traditional rules. A typical course covers the phonetic characteristics of each letter, proper enunciation, and rules for elongation, pauses, and intonation. Students practice through repetitive exercises and recitation to achieve fluency and accuracy. Mastery of Tajweed is essential for anyone seeking to recite the Quran as it was revealed, preserving its original sound and meaning."
      },
      {
        "title": "Nazra Quran Course",
        "description": "The Nazra Quran Course focuses on teaching the recitation of the Quran with proper pronunciation and Tajweed rules. Aimed at beginners and those seeking to improve their Quranic reading skills, the course emphasizes correct phonetics, fluency, and understanding of the Quranic text. Students learn to read the Quranic verses accurately while developing a deeper connection with the holy text. The course often includes practice sessions, one-on-one instruction, and reviews to ensure progress. By mastering Nazra, learners gain the foundation for more advanced studies in Quranic recitation and memorization."
      },
      {
        "title": "Hifaz ul Quran Course",
        "description": "The Hifaz ul Quran Course is a comprehensive program designed to guide students in memorizing the entire Quran. It combines structured lessons with personal coaching to ensure effective memorization and retention. The course typically involves regular recitation, review sessions, and periodic assessments to track progress. Students are taught various memorization techniques and are provided with supportive resources to enhance their learning experience. With a focus on both accuracy and understanding, the course aims to instill a deep connection with the Quran, enabling students to preserve and recite its verses with confidence and precision."
      },
      {
        "title": "Qirat ul Quran Course",
        "description": "The Qirat ul Quran course focuses on mastering the correct pronunciation and recitation of the Quran. It covers the rules of Tajweed, which ensure that the Quran is read as it was revealed to Prophet Muhammad. The course includes learning the various styles of Qirat, which are essential for accurate recitation. Students practice through guided recitations, receive feedback on their pronunciation, and develop an understanding of the rules governing each style. The goal is to enhance the recitation skills, deepen the connection with the Quran, and foster a respectful and correct engagement with the sacred text."
      },
      {
        "title": "Translation of Holy Quran Course",
        "description": "This course explores the translation of the Holy Quran, focusing on understanding its meanings in various languages while maintaining its linguistic and theological integrity. Students will study different translation approaches, the challenges of conveying the Quranic message accurately, and the historical context of various translations. The course also examines key concepts, terminology, and the impact of translation on Muslim communities and interfaith dialogue. Through analysis of renowned translations and hands-on practice, learners gain insights into the nuances of Quranic text and the principles guiding faithful and effective translation."
      },
      {
        "title": "Tafseer ul Quran Course",
        "description": "A Tafseer ul Quran course focuses on the in-depth interpretation and understanding of the Quranic text. It covers the historical context, linguistic nuances, and theological meanings of Quranic verses. Students explore classical and contemporary commentaries, learning to apply the teachings of the Quran to modern life. The course typically includes studies of Tafseer methodologies, including Asbab al-Nuzul (reasons for revelation) and Ijma (consensus). By engaging with the Quranic text through various perspectives, participants gain a deeper appreciation and practical insight into Islamic principles and values."
      },
      {
        "title": "Hadith Course",
        "description": "A Hadith course typically covers the sayings and actions of the Prophet Muhammad (peace be upon him) as recorded in Islamic tradition. It explores the classification of Hadith into categories like Sahih (authentic), Daif (weak), and Hasan (good). The course examines the principles of Hadith science, including the chain of transmission (isnad) and the content (matn). Students learn about notable Hadith scholars, methodologies for evaluating Hadith, and their applications in Islamic jurisprudence and daily life. Understanding Hadith is essential for interpreting the Quran and implementing Islamic teachings accurately."
      },
      {
        "title": "Fiqh Course",
        "description": "A Fiqh course provides an in-depth understanding of Islamic jurisprudence, focusing on the principles and rules derived from the Quran and Hadith. It covers various aspects of daily life, including worship, transactions, and personal conduct, aiming to guide Muslims in adhering to Sharia (Islamic law). Students learn about the different schools of thought, interpretation methods, and the application of legal rulings. The course often includes discussions on contemporary issues, ensuring that traditional teachings are relevant to modern contexts. Overall, it equips individuals with the knowledge to make informed decisions and live in accordance with Islamic principles."
      },
      {
        "title": "Arabic Grammar Course",
        "description": "Arabic grammar, or 'nahw,' focuses on the structure and rules governing sentence formation. Key elements include: 1. Nouns (Ism): Classified as definite or indefinite, and can be singular, dual, or plural. 2. Verbs (Fi'l): Conjugated based on tense (past, present, future), and vary by subject (singular, plural). 3. Adjectives (Sifa): Agree with nouns in gender, number, and case. 4. Cases (I'raab): Nouns and adjectives have different case endings (nominative, accusative, genitive). 5. Pronouns: Include personal, possessive, and demonstrative types. Understanding these basics helps in constructing and interpreting Arabic sentences correctly."
      },
      {
        "title": "English Language Course",
        "description": "This English language course introduces fundamental skills for effective communication. Students will cover essential grammar rules, including verb tenses, sentence structure, and punctuation. Vocabulary expansion is emphasized through thematic lessons and practical exercises. Listening and speaking activities are designed to enhance pronunciation and conversational abilities. Reading comprehension is developed with diverse texts, ranging from articles to short stories. Writing skills are refined through structured practice, including essays and reports. The course also includes interactive components like group discussions and role-plays to build confidence. Overall, the goal is to improve fluency and proficiency in both written and spoken English."
      },
      {
        "title": "Al-Taswauf Course",
        "description": "A Taswaf course explores the spiritual and ethical teachings of Sufism, focusing on personal development and inner purification. Students learn about the principles of Sufism, including concepts such as divine love, humility, and the quest for closeness to God. The course typically covers practices such as dhikr (remembrance of God), meditation, and the study of Sufi literature and poetry. Emphasis is placed on the inner dimensions of faith, self-discipline, and the cultivation of virtues. Through lectures, discussions, and practical exercises, participants aim to deepen their spiritual understanding and enhance their personal connection to the divine."
      },
      {
        "title": "Arabic Language Course",
        "description": "In our Arabic language course, we will teach you the basics of reading, writing, speaking, and understanding Arabic. You will learn the Arabic alphabet, common phrases, and simple sentences to help you communicate in everyday situations. Our lessons will cover essential vocabulary and grammar rules to build your language skills step by step. We will also introduce you to Arabic culture, traditions, and customs, making your learning experience more enriching. With interactive exercises and practical conversations, you will gain confidence in using Arabic in real-life scenarios. By the end of the course, you will have a strong foundation in the Arabic language."
      }
    ]
  
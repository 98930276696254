import React from 'react';

import FeeCard from '../../../components/e-learning/ui/FeeCard.jsx';
import FeeCardMini from '../../../components/e-learning/ui/FeeCardMini.jsx';


const Fee = () => {
  return (
    <div className="bg-colorBg pb-10">
      <div className='mx-auto max-w-7xl md:p-10  '>
        <div className='pt-32 p-5'>
          <h1 className='text-3xl font-bold'>
          QURAN READING COURSE
          </h1>
          <p className='mt-5 ps-1 text-lg'>
          We present our students with a progressive fee schedule to ease them out. Our offer includes:
          </p>
          <ul className='list-disc ps-7 mt-3 text-lg' >
            <li>
            3 days free trial to individuals
            </li>
            <li>
            Second student is cushioned with 50% discount
            </li>
            <li>
            Fee structure presented below is exclusive of 3 days trial period and applies for monthly basis once clients are satisfied enough to continue classes.
            </li>
          </ul>


        </div>
        <div className='sm:block hidden'>
        <div className='mt-10'>
        <FeeCard type='Group 1'/>
        </div>
        <div className='mt-10'>
        <FeeCard type='Group 2'/>
        </div>
        <div className='mt-10'>
        <FeeCard type='Group 3'/>
        </div>

        </div>
        <div className='sm:hidden block'>
        <div className='mt-10'>
        <FeeCardMini type='Group 1'/>
        </div>
        <div className='mt-10'>
        <FeeCardMini type='Group 2'/>
        </div>
        <div className='mt-10'>
        <FeeCardMini type='Group 3'/>
        </div>

        </div>
      
      </div>
      
    </div>
  )
}

export default Fee
import React from 'react'
import MainPage from './MainPage'

const SchoolAdmission = () => {
  return (
    <section className='pt-16 md:pt-28 '>
        <MainPage/>
    </section>
  )
}

export default SchoolAdmission
import React from "react";

const FeeCardBoarder = () => {
  return (
    <div className="facilitesLayout flex flex-col items-center  justify-center px-10 md:py-10 py-8  max-w-7xl mx-auto gap-y-5">
      <div className="facilitiesElements  mt-14 sm:mt-16 md:mt-20 ">
        <h4 className="text-xl mb-4   text-colorPrimaryMain   text-center">
          THE FEE STRUCTURE
        </h4>
        <div className="broaderCard flex-col items-center text-center">
          <h2 className="text-xl sm:text-2xl md:text-4xl font-semibold text-blue-950 px-4  my-2">
            Detail of Fee & Other Dues for
            <span className="block">Boarder (Session 2024)</span>
          </h2>

          <div className="card rounded-lg grid grid-cols-1 md:grid-cols-2  shadow-sm mt-2 md:mt-8 bg-white p-5">
            <div className="p-2 px-3  border-r border-gray-200">
              <p className="text-colorPrimaryMain mb-2 font-semibold text-start">
                At the time of Admission
              </p>
              <div className="feeStructure my-2">
                <li className="flex justify-between items-center my-2 ">
                  <p className="flex items-center gap-2">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26Z"
                        fill="#4A3AFF"
                      />
                      <path
                        d="M7.1167 13.8405L10.4785 17.2023L18.8831 8.79773"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="text-blue-950 font-medium">
                      Admission Fee
                    </span>
                  </p>
                  <p className="text-xl text-blue-950">Rs. 2000</p>
                </li>
                <li className="flex justify-between items-center my-2 ">
                  <p className="flex items-center gap-2">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26Z"
                        fill="#4A3AFF"
                      />
                      <path
                        d="M7.1167 13.8405L10.4785 17.2023L18.8831 8.79773"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="text-blue-950 font-medium">
                      Furniture Fund
                    </span>
                  </p>
                  <p className="text-xl text-blue-950">Rs. 1500</p>
                </li>
                <li className="flex justify-between items-center my-2 ">
                  <p className="flex items-center gap-2">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26Z"
                        fill="#4A3AFF"
                      />
                      <path
                        d="M7.1167 13.8405L10.4785 17.2023L18.8831 8.79773"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="text-blue-950 font-medium">
                      Building Fund
                    </span>
                  </p>
                  <p className="text-xl text-blue-950">Rs. 1500</p>
                </li>
                <li className="flex justify-between items-center my-2 ">
                  <p className="flex items-center gap-2">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26Z"
                        fill="#4A3AFF"
                      />
                      <path
                        d="M7.1167 13.8405L10.4785 17.2023L18.8831 8.79773"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="text-blue-950 font-medium">Misc Fund</span>
                  </p>
                  <p className="text-xl text-blue-950">Rs. 1000</p>
                </li>
                <li className="flex justify-between items-center my-2 ">
                  <p className="flex items-center gap-2">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26Z"
                        fill="#4A3AFF"
                      />
                      <path
                        d="M7.1167 13.8405L10.4785 17.2023L18.8831 8.79773"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="text-blue-950 font-medium">
                      Security-Refundable
                    </span>
                  </p>
                  <p className="text-xl text-blue-950">Rs. 2500</p>
                </li>
              </div>
              <p className="py-2 border-t border-gray-200 flex items-center justify-between">
                <span className="text-colorPrimaryMain block text-2xl">
                  Total
                </span>
                <span className="text-colorPrimaryMain block text-2xl">
                  Rs. 8500
                </span>
              </p>
            </div>
            <div className="p-2  px-4">
              <p className="text-colorPrimaryMain mb-2 font-semibold text-start">
                Monthly Fee
              </p>
              <div className="feeStructure">
              <li className="flex justify-between items-center my-2 ">
                  <p className="flex items-center gap-2">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26Z"
                        fill="#4A3AFF"
                      />
                      <path
                        d="M7.1167 13.8405L10.4785 17.2023L18.8831 8.79773"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="text-blue-950 font-medium">
                      Tuition Fee
                    </span>
                  </p>
                  <p className="text-xl text-blue-950">Rs. 2000</p>
                </li>
                <li className="flex justify-between items-center my-2 ">
                  <p className="flex items-center gap-2">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26Z"
                        fill="#4A3AFF"
                      />
                      <path
                        d="M7.1167 13.8405L10.4785 17.2023L18.8831 8.79773"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="text-blue-950 font-medium">
                      Hostel 
                    </span>
                  </p>
                  <p className="text-xl text-blue-950">Rs. 4000</p>
                </li>
                <li className="flex justify-between items-center my-2 ">
                  <p className="flex items-center gap-2">
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 26C20.1799 26 26 20.1799 26 13C26 5.8201 20.1799 0 13 0C5.8201 0 0 5.8201 0 13C0 20.1799 5.8201 26 13 26Z"
                        fill="#4A3AFF"
                      />
                      <path
                        d="M7.1167 13.8405L10.4785 17.2023L18.8831 8.79773"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="text-blue-950 font-medium">
                      Mess
                    </span>
                  </p>
                  <p className="text-xl text-blue-950">Rs. 5000</p>
                </li>
                <li className="list-none">
                <p className="py-2 border-t border-gray-200 flex items-center justify-between">
                <span className="text-colorPrimaryMain block text-2xl">
                  Total
                </span>
                <span className="text-colorPrimaryMain block text-2xl">
                  Rs. 2500
                </span>
              </p>
                </li>

                <li className="list-none mt-2" >
                  <p className="bg-gradient-to-r from-colorPrimaryMain to-blue-500 p-2 rounded-lg">
                    <span className="text-gray-200 block text-sm">
                       Grand Total of Fee at the time of Admission
                    </span>
                    <span className="text-gray-200 block text-center text-xl">
                       Rs. 21500 
                    </span>
                  </p>
                </li>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeCardBoarder;

import React from 'react'
import Carousel from './Carousel';
import Testimonials from './Testimonials';
import About from './About';
import Admission from './Admission';
import Events from '../../../components/school/ui/Events';
import Review from './Review';


const HomePage = () => {
  const titleColor = "text-white";
  return (
    <section className=' pt-16 md:pt-28 border-t-4 border-red-500 pb-12'>
        <Carousel/>
        <Testimonials/>
        <About/>
        <Admission/>
        <Events bgColor={"bg-schoolColorMain"} headingColor={titleColor}/>
        <Review/>
    </section>
  )
}

export default HomePage
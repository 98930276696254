import React from 'react';
import syllabusImg from "../../../assets/tahfeezul -quran/img/tahfeezul-quran-syllabus.jpg";

const SyllabusSection = () => {
  return (
    <div className="syllabusLayout flex flex-col items-center  justify-center p-10 max-w-7xl mx-auto gap-y-5">
      <div className="syllabusElements  flex flex-col  items-center  mt-14 sm:mt-16 md:mt-20 ">
      <h2 className="mr-auto text-xl mb-8 sm:text-2xl md:text-3xl lg:text-4xl font-bold  text-blue-600  ">
       Syllabus
      </h2>

      <div className="syllabusImg ">
        <img src={syllabusImg} className='w-full h-full ' alt="syllabus img" />
      </div>
    </div>
    </div>
  )
}

export default SyllabusSection
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../slider.css";
import pic1 from "../../../assets/e-learning/img/elearning-carousel-img1.jpg";
import pic2 from "../../../assets/e-learning/img/elearning-carousel-img2.jpg";
import pic3 from "../../../assets/e-learning/img/elearning-carousel-img3.jpg";
import pic4 from "../../../assets/e-learning/img/elearning-carousel-img4.jpg";

function HeroSlider() {
  const settings = {
    className: "center",
    arrows:false,
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 2,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 2000,
            dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 1,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 2000,
            dots: true,
        },
      },
    ],
  };

  return (
    <div>
      <div id="home-slider" className="slider-container pt-40 pb-12">
        <Slider {...settings}>
          <div>
            <img src={pic1} alt="Slide 1" />
          </div>
          <div>
            <img src={pic2} alt="Slide 2" />
          </div>
          <div>
            <img src={pic3} alt="Slide 3" />
          </div>
          <div>
            <img src={pic4} alt="Slide 4" />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default HeroSlider;

import React from 'react'
import offerBg from '../../../assets/e-learning/img/e-learning-offers-bg.png'
import OffersSlider from './OffersSlider'
const Offers = () => {
  return (
    <div style={{'backgroundImage':`url(${offerBg})` ,'backgroundSize':'cover','backgroundRepeat':'no-repeat'}} className='md:py-20 py-20' >
        <div className='mx-auto max-w-7xl '>
        <div className='flex flex-col items-center justify-center  mx-auto gap-y-5'>
        <h1 className='text-xl font-semibold sm:text-4xl sm:font-medium text-center mb-10'>
        Course We Offer
        </h1>
    </div>
    <OffersSlider/>
        </div>
        
    </div>
  )
}

export default Offers
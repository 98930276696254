import React from 'react';
import offer1img from '../../../assets/e-learning/img/e-learning-herosection-bg.jpg';
import { useNavigate } from 'react-router-dom';

const OfferCard = ({ title, description }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    const encodedTitle = encodeURIComponent(title);
    const encodedDescription = encodeURIComponent(description);
    navigate(`/elearning/courses/coursedetails?title=${encodedTitle}&description=${encodedDescription}`);
  };

  return (
    <div className='bg-white p-2 rounded-lg m-2'>
      <img className='rounded-md ' src={offer1img} alt="" />
      <div className='p-3'>
        <h1 className='text-lg font-bold'>{title}</h1>
        <p className='text-justify'>
          {description?.split(' ')?.slice(0, 10)?.join(' ') + '...'}
        </p>
        <div className='flex justify-between w-full gap-2 mt-4'>
          <button className='w-full bg-colorPrimary text-white my-1 rounded-md py-2' onClick={() => navigate('/elearning/registration')}>
            Apply Now
          </button>
          <button className='w-full bg-colorBg text-colorPrimary my-1 rounded-md py-2' onClick={handleViewDetails}>
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;

import React from 'react'
import GalleryPics from './GalleryPics'

const Gallery = () => {
  return (
    <section className='pt-20 md:pt-28 '>
        <GalleryPics/>
    </section>
  )
}

export default Gallery
import React from 'react'

const FeeCardMini = ({type}) => {
  return (
    <div className='m-2 bg-white rounded-lg shadow-lg p-4'>
              <div className='flex justify-start items-center gap-x-4'>
<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="72" height="72" rx="16" fill="#E9FEF2"/>
<path d="M36 17C30.9609 17 26.1282 19.0018 22.565 22.565C19.0018 26.1282 17 30.9609 17 36C17 41.0391 19.0018 45.8718 22.565 49.435C26.1282 52.9982 30.9609 55 36 55L36 36L36 17Z" fill="url(#paint0_linear_335_284)"/>
<path d="M36 55C41.0391 55 45.8718 52.9982 49.435 49.435C52.9982 45.8718 55 41.0391 55 36C55 30.9609 52.9982 26.1282 49.435 22.565C45.8718 19.0018 41.0391 17 36 17L36 36L36 55Z" fill="#C9F4DA"/>
<defs>
<linearGradient id="paint0_linear_335_284" x1="17" y1="36" x2="55" y2="36" gradientUnits="userSpaceOnUse">
<stop offset="0.425" stop-color="#2AC465"/>
<stop offset="1" stop-color="#35D572"/>
</linearGradient>
</defs>
</svg>
<div className=''>
  <h1 className='text-gray-600'>
  For individuals
  </h1>
  <h1 className='font-me text-xl font-semibold'>
  {type}
  </h1>
</div>
</div>
<div className='my-4'>
    <h1 className='text-lg font-semibold'>
        Courses
    </h1>
    <div className='my-2 rounded-lg rouned'>
        <h1 className='border-b border-gray-300 bg-gray-100 p-2 rounded-t-lg'>
        Quran Reading Course
        </h1>
        <h1 className='border-b border-gray-300 bg-gray-100 p-2'>
        Quran Reading Course
        </h1>
        <h1 className='border-gray-300 bg-gray-100 p-2 rounded-b-lg'>
        Quran Reading Course
        </h1>
    </div>
    

</div>

<div>
    <div className='flex justify-between  items-center text-nowrap gap-x-1 text-sm bg-colorPrimary text-white p-2 rounded-t-lg'>
        <h1>Currency</h1>
        <h1>{`Plan A (5 Days)`}</h1>
        <h1>{`Plan B (2 Days)`}</h1>
    </div>
    <div className='flex justify-between  items-center text-nowrap gap-x-1 text-sm bg-colorBg  text-gray-600  p-2'>
        <h1>Autrailia</h1>
        <h1>{`86 AUD`}</h1>
        <h1>{`86 AUD`}</h1>
    </div>
    <div className='flex justify-between  items-center text-nowrap gap-x-1 text-sm bg-colorBg  text-gray-600  p-2'>
        <h1>Autrailia</h1>
        <h1>{`86 AUD`}</h1>
        <h1>{`86 AUD`}</h1>
    </div>

    <div className='flex justify-between  items-center text-nowrap gap-x-1 text-sm bg-colorBg  text-gray-600  p-2'>
        <h1>Autrailia</h1>
        <h1>{`86 AUD`}</h1>
        <h1>{`86 AUD`}</h1>
    </div>

    <div className='flex justify-between  items-center text-nowrap gap-x-1 text-sm bg-colorBg  text-gray-600  p-2'>
        <h1>Autrailia</h1>
        <h1>{`86 AUD`}</h1>
        <h1>{`86 AUD`}</h1>
    </div>

    <div className='flex justify-between  items-center text-nowrap gap-x-1 text-sm bg-colorBg  text-gray-600  p-2'>
        <h1>Autrailia</h1>
        <h1>{`86 AUD`}</h1>
        <h1>{`86 AUD`}</h1>
    </div>

    <button className='bg-colorPrimary w-full my-2 rounded text-white py-1'>
        Join now
    </button>


</div>

    </div>
  )
}

export default FeeCardMini
import React, { useState } from 'react';

const About = () => {
  const [ lineClamp, setLineClamp ] = useState(true);
  return (
    <section className='flex flex-col items-center  justify-center p-4 sm:px-8 pt-8 pb-20 md:p-10 max-w-7xl mx-auto gap-y-5'>
       <div className=" flex flex-col justify-center  items-center ">
           <h1 className='text-gray-800 text-3xl sm:text-4xl md:text-5xl sm:font-semibold md:font-semibold'>
             About The Sufians
           </h1>
          
           <iframe  className='h-[40vh] w-[60vw]  p-2 mt-2 sm:mt-6 md:mt-8'  src="https://www.youtube.com/embed/D8PY4Iha0Q8?si=GHP2cVMjiQk12Etk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

           <p className={`text-center mt-4 sm:mt-6 md:mt-8 text-base sm:text-lg md:text-xl  text-gray-800 ${lineClamp? "line-clamp-4":"line-clamp-none"}`}>
              Established in November 1975 as the Les Anges Montessori Academy for toddlers, Beaconhouse has since grown into a global network of private schools, institutes, and universities, imparting distinctive and meaningful education to students from pre-school to post-graduation. The Educators, a Project of Beaconhouse, has completed more than 20 years of service to educational development across the country with remarkable success. Established in 2002, it now has 1,000+ campuses & the market leader in Pakistan's educational landscape with a presence in over 275+ cities and a student strength exceeding 225,000+. This makes The Educators outreach the largest compared to any other private school in the world

           </p>
           <button className='flex flex-col mx-2 text-base text-red-500  items-end ml-auto' onClick={()=> setLineClamp(!lineClamp)}>
              {lineClamp? "Show less...": "Read more..." }
            </button>

           <button className=' md:mt-8 px-24  py-3 rounded-xl  text-center bg-schoolColorMain text-white'>
             Explore
           </button>
       </div>
    </section>
  )
}

export default About
import React from "react";
import urduForm from "../../../assets/tahfeezul -quran/img/admission-form-urdu.jpg";
import pdfIcon from "../../../assets/tahfeezul -quran/img/pdf-image-icon.png";

const AdmissionForm = () => {
  return (
    <div className="admissionLayout flex flex-col items-center  justify-center p-10 max-w-7xl mx-auto gap-y-5">
      <h2 className="mr-auto text-xl mt-2 md:mt-0 mb-0 md:mb-4 sm:text-2xl md:text-3xl lg:text-4xl font-bold  text-blue-600  ">
        Admission Form
      </h2>

        <div className="forms mt-2 grid grid-cols-1 md:grid-cols-2 flex-col ">
            <div className="urduForm relative group  flex-col ">
                <div className="form group-hover:brightness-50  ">
                    <img src={urduForm} className="w-screen" alt="Admission form  urdu " />
                </div>
                <div className="pdfImg invisible group-hover:visible absolute grid top-[30%] left-[35%]">
                    <img src={pdfIcon} alt="pdf icon" />
                </div>
            </div>
            <div className="urduForm relative group ">
                <div className="form group-hover:brightness-50 ">
                    <img src={urduForm} className="w-screen"  alt="Admission form  urdu " />
                </div>
                <div className="pdfImg invisible group-hover:visible absolute grid top-[30%] left-[35%]">
                    <img src={pdfIcon} alt="pdf icon" />
                </div>
            </div>

        </div>
    </div>
  );
};

export default AdmissionForm;

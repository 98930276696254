import React from 'react';
import cardLogoR from "../../../assets/e-learning/img/e-learning-cardLLogo.png";
import cardLogoL from "../../../assets/e-learning/img/e-learning-cardRLogo.png";


const CourseCard = () => {
    return (
        <div className="card shadow text-center  bg-white rounded-lg py-4">
        <div className=" flex p-4 gap-4 border-b border-gray-200">
          <div className="cardElements flex gap-4 ">
            <div >
              {/* <div className="w-10 flex">
                <img src={cardLogoR} alt="left Logo" />
                <img src={cardLogoL} alt="right Logo" />
              </div> */}
                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="72" height="72" rx="16" fill="#E9FEF2"/>
<path d="M36 17C30.9609 17 26.1282 19.0018 22.565 22.565C19.0018 26.1282 17 30.9609 17 36C17 41.0391 19.0018 45.8718 22.565 49.435C26.1282 52.9982 30.9609 55 36 55L36 36L36 17Z" fill="url(#paint0_linear_335_284)"/>
<path d="M36 55C41.0391 55 45.8718 52.9982 49.435 49.435C52.9982 45.8718 55 41.0391 55 36C55 30.9609 52.9982 26.1282 49.435 22.565C45.8718 19.0018 41.0391 17 36 17L36 36L36 55Z" fill="#C9F4DA"/>
<defs>
<linearGradient id="paint0_linear_335_284" x1="17" y1="36" x2="55" y2="36" gradientUnits="userSpaceOnUse">
<stop offset="0.425" stop-color="#2AC465"/>
<stop offset="1" stop-color="#35D572"/>
</linearGradient>
</defs>
</svg>
            </div>
          </div>
          <div>
            <p className="text-gray-600 font-medium"> For Beginners</p>
            <h4 className="font-semibold  text-start text-2xl ">
                 Basic                 </h4>
          </div>
        </div>

            <div className="  py-2 rounded-lg ">
                <p className=" py-2  flex justify-between border-b border-gray-200">
                    <span className=" pl-4 text-gray-700">
                        Course Type
                    </span>
                    <span className=" pr-4 text-colorPrimary">
                        Certificate
                    </span>
                </p>
                <p className=" py-2  flex justify-between border-b border-gray-200">
                    <span className=" pl-4 text-gray-700">
                        Course Duration
                    </span>
                    <span className=" pr-4 text-colorPrimary">
                        30 Minutes
                    </span>
                </p>
                <p className=" py-2  flex justify-between border-b border-gray-200">
                    <span className=" pl-4 text-gray-700">
                        Total Classes
                    </span>
                    <span className=" pr-4 text-colorPrimary">
                        80 Minutes
                    </span>
                </p>
                <div className=" py-2  ">
                    <p className=" text-start pl-4 text-gray-800 w-full font-medium">
                        Course Duration 
                    </p>
                    <div className="flex justify-evenly p-2">
                    <p className="p-2 lg:p-4  text-colorPrimary  bg-gray-100 rounded-lg ">
                        <span className='p-2 block text-gray-800 py-1'> Plan  A </span>
                        <span className=' block py-1 text-colorPrimary'> 4 Months</span>
                    </p>
                    <p className=" p-2 lg:p-4 text-colorPrimary bg-gray-100 rounded-lg">
                        <span className=' block text-gray-800 py-1'> Plan  A </span>
                        <span className='block py-1 text-colorPrimary'> 4 Months</span>
                    </p>
                    </div>
                </div>

    
            </div>
    
        <div className=" mt-2 flex justify-end pr-8">
        <button className="bg-colorPrimary text-gray-50   py-2 px-16 rounded-lg  "> 
            Join Now 
        </button>
        </div>
    
        </div>
      );
}

export default CourseCard
import React from "react";
import detailImg from "../../../assets/e-learning/img/e-learning-course-detail.png";

const Detail = ({ title, description }) => {
  return (
    <div className="DetailLayout flex flex-col  justify-center p-10 max-w-7xl mx-auto gap-y-5 ">
      <div className="DetailElements mt-14 sm:mt-16 md:mt-20 text-start   block lg:flex  gap-4">
        <div className="description w-full lg:w-3/5">
          <h1 className="font-semibold flex justify-start text-2xl sm:text-3xl lg:text-4xl mb-2">
          {title}
          </h1>
          <p className="text-gray-700 py-1">
          {description}
          
          </p>
        </div>
        <div className="img flex justify-center items-center w-full mt-8 lg:w-1/3">
            <img className="rounded-lg" src={detailImg} alt=" course detail img" />
        </div>
      </div>
    </div>
  );
};

export default Detail;





import React from 'react'

const News = () => {
  return (
    <div className=' flex justify-between items-center rounded-full px-2 py-2 border gap-x-2 bg-white'>
        <h1 className='bg-colorPrimaryMain text-white px-4 py-1 rounded-full text-lg  '>
            News
        </h1>

    <marquee className='rounded-full text-colorPrimaryMain text-lg ' behavior="" direction="">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam vel assumenda veniam labore at autem consectetur minima possimus nostrum inventore!
    </marquee>
  
    </div>
  )
    
}

export default News
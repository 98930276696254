import React from 'react'
import AdmissionCritera from './AdmissionCritera'
import AdmissionForm from './AdmissionForm'

const Admission = () => {
  return (
    <div className=''>
        <AdmissionCritera/>
        <AdmissionForm/>
    </div>
  )
}

export default Admission
import React from 'react'
import offer1img from '../../../assets/e-learning/img/e-learning-herosection-bg.jpg'
const CourseCard = () => {
  return (
    <div className='bg-white p-2 rounded-lg m-2'>
        <img className='rounded-md ' src={offer1img} alt="" />
        <div className='p-3'>
            <h1 className='text-lg font-bold'>Fiqh Learning Course</h1>
            <p className='text-justify'>Literally the word ‘Fiqh’ means to know, to understand, and to grasp something...</p>
            <div className='flex justify-between w-full gap-2 mt-4'>
                <button className='w-full bg-colorPrimary text-white my-1 rounded-md py-2 '>Apply Now</button>
                <button className='w-full bg-colorBg text-colorPrimary my-1 rounded-md py-2 '>View Details</button>
            </div>
        </div>
    </div>
  )
}

export default CourseCard
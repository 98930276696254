import React from 'react'
import offer1img from '../../../assets/e-learning/img/e-learning-herosection-bg.jpg'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
const FeeCard = ({type}) => {
  const navigate=useNavigate();
  const columns = [
    {
      name: 'Currency',
      selector: row => row.currency,
    },
    {
      name: 'Plan A (5 Days)',
      selector: row => row.planA,
    },
    {
      name: 'Plan B (2 Days)',
      selector: row => row.planB,
    },
  ];
  
  const data = [
      {
      id: 1,
      currency: 'Autrailia',
      planA:'86 AUD',
      planB:'86 AUD',
    },
    {
      id: 1,
      currency: 'Autrailia',
      planA:'86 AUD',
      planB:'86 AUD',
    },
    {
      id: 1,
      currency: 'Autrailia',
      planA:'86 AUD',
      planB:'86 AUD',
    },
    {
      id: 1,
      currency: 'Autrailia',
      planA:'86 AUD',
      planB:'86 AUD',
    },
    {
      id: 1,
      currency: 'Autrailia',
      planA:'86 AUD',
      planB:'86 AUD',
    },
    
  ]
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: 'hsla(143, 65%, 47%, 1)',
        color: '#ffffff',
        border: 'transparent',
        fontSize:'16px',
        fontWeight:'normal'
      },
    },
    rows: {
      style: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#E9FEF2',
          border:'transparent',
          fontSize:'16px'
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#ffffff',
          border:'transparent',
          fontSize:'16px'
        },
      },
    },
  };
  return (
    <div className='m-2 bg-white rounded-lg shadow-lg flex flex-col'>
      <div className='flex flex-col sm:flex sm:flex-row justify-between items-center  md:p-10'>
      <div className='flex flex-row md:flex-col  lg:gap-y-8 justify-between'>
      <div className='flex justify-between items-center gap-x-4'>
<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="72" height="72" rx="16" fill="#E9FEF2"/>
<path d="M36 17C30.9609 17 26.1282 19.0018 22.565 22.565C19.0018 26.1282 17 30.9609 17 36C17 41.0391 19.0018 45.8718 22.565 49.435C26.1282 52.9982 30.9609 55 36 55L36 36L36 17Z" fill="url(#paint0_linear_335_284)"/>
<path d="M36 55C41.0391 55 45.8718 52.9982 49.435 49.435C52.9982 45.8718 55 41.0391 55 36C55 30.9609 52.9982 26.1282 49.435 22.565C45.8718 19.0018 41.0391 17 36 17L36 36L36 55Z" fill="#C9F4DA"/>
<defs>
<linearGradient id="paint0_linear_335_284" x1="17" y1="36" x2="55" y2="36" gradientUnits="userSpaceOnUse">
<stop offset="0.425" stop-color="#2AC465"/>
<stop offset="1" stop-color="#35D572"/>
</linearGradient>
</defs>
</svg>
<div className=''>
  <h1 className='text-gray-600'>
  For individuals
  </h1>
  <h1 className='font-me text-xl font-semibold'>
  {type}
  </h1>
</div>
</div>
<div className=''>
  <h1 className='text-xl font-semibold ml-2'>
    Courses
  </h1>
  <p className='px-5 py-2 border-b border-gray-200 mt-2'> Quran Reading Course</p>
  <p className='px-5 py-2 border-b border-gray-200 mt-2'> Quran Reading Course</p>
  <p className='px-5 py-2 border-gray-200 mt-2'> Quran Reading Course</p>

</div>
      </div>
      <div className='w-full lg:w-3/5 rounded-lg '>
      <DataTable
			columns={columns}
      customStyles={customStyles}
			data={data}
		/>
      </div>
  


    </div>
    <div className='md:px-10 md:py-5 ml-auto'>
      <button className='bg-colorPrimary px-7 py-2 rounded-md text-white' onClick={()=>navigate('/elearning/registration')}> Join Now</button>
    </div>
    </div>
  )
}

export default FeeCard
import React from 'react';
import Contact from './Contact.jsx';


const ContactUs = () => {
  return (
    <div className="bg-colorBg">
        <Contact/>
    </div>
  )
}

export default ContactUs
import React from 'react';
import SelectInput from '../../../components/e-learning/ui/SelectInput';


const Registartion = () => {
  return (
    <div className="bg-colorBg">
       <div className='mx-auto max-w-7xl py-32 p-5'>
        <h1 className='font-semibold text-3xl mb-5'>
        Registration
        </h1>
        <p className='text-lg text-gray-700 mb-5 text-justify '>
        The Holy Quran is Divine book that contains the instructions which are the only way to become victorious not only the Day after Judgment but also in this world. The reading, the understanding and knowledge of translation of The Holy Quran are mandatory upon every Muslim. We warmly welcome all those who wants to learn The Holy Quran and desire to fill their lives with spiritual blessings.
        </p>
        <div className='bg-white w-full rounded-lg'>
          <div className='w-full border-b py-3 ps-4 text-lg font-semibold rounded-t-lg'>
            Details

          </div>
          <div className='grid grid-cols-2 p-4 gap-4'>
          <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
        </div>
        <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
        </div>
        <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
        </div>
        <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
        </div>


       
<div>
<h3 class="mb-2 text font-medium text-gray-900 dark:text-white">Student Gender</h3>
<ul class="grid w-full gap-6 md:grid-cols-2">

    <li>
        <input type="radio" id="hosting-big" name="hosting" value="hosting-big" class="hidden peer"/>
        <label for="hosting-big" class="flex flex-col items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
        <svg className='' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4844 26.5469C14.3492 26.5469 16.1376 25.8061 17.4562 24.4875C18.7748 23.1689 19.5156 21.3804 19.5156 19.5156C19.5156 17.6508 18.7748 15.8624 17.4562 14.5438C16.1376 13.2252 14.3492 12.4844 12.4844 12.4844C10.6196 12.4844 8.83115 13.2252 7.51253 14.5438C6.19392 15.8624 5.45312 17.6508 5.45312 19.5156C5.45312 21.3804 6.19392 23.1689 7.51253 24.4875C8.83115 25.8061 10.6196 26.5469 12.4844 26.5469ZM12.4844 28.3047C10.1534 28.3047 7.91784 27.3787 6.26957 25.7304C4.6213 24.0822 3.69531 21.8466 3.69531 19.5156C3.69531 17.1846 4.6213 14.9491 6.26957 13.3008C7.91784 11.6526 10.1534 10.7266 12.4844 10.7266C14.8154 10.7266 17.0509 11.6526 18.6992 13.3008C20.3475 14.9491 21.2734 17.1846 21.2734 19.5156C21.2734 21.8466 20.3475 24.0822 18.6992 25.7304C17.0509 27.3787 14.8154 28.3047 12.4844 28.3047ZM20.3945 3.69531H27.4258C28.0117 3.69531 28.3047 3.98828 28.3047 4.57422C28.3047 5.16016 28.0117 5.45312 27.4258 5.45312H20.3945C19.8086 5.45312 19.5156 5.16016 19.5156 4.57422C19.5156 3.98828 19.8086 3.69531 20.3945 3.69531Z" fill="#828282"/>
<path d="M27.4258 3.69531C28.0117 3.69531 28.3047 3.98828 28.3047 4.57422V11.6055C28.3047 12.1914 28.0117 12.4844 27.4258 12.4844C26.8398 12.4844 26.5469 12.1914 26.5469 11.6055V4.57422C26.5469 3.98828 26.8398 3.69531 27.4258 3.69531Z" fill="#828282"/>
<path d="M18.9012 14.3406L17.6602 13.0978L26.8043 3.95197L28.0488 5.19647L18.9012 14.3406Z" fill="#828282"/>
</svg>
            <div class="block">
                <div class="w-full text-lg font-semibold">Male</div> 
            </div>


        </label>
    </li>
    <li>
        <input type="radio" id="hosting-big" name="hosting" value="hosting-big" class="hidden peer"/>
        <label for="hosting-big" class="flex flex-col items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
        <svg width="20" height="34" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.55556 27.6667H15.2222M9.88889 18.7778V33M9.88889 18.7778C14.798 18.7778 18.7778 14.798 18.7778 9.88889C18.7778 4.9797 14.798 1 9.88889 1C4.9797 1 1 4.9797 1 9.88889C1 14.798 4.9797 18.7778 9.88889 18.7778Z" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            <div class="block">
                <div class="w-full text-lg font-semibold">Female</div> 
            </div>
        </label>
    </li>
</ul>
</div>
<div>
<h3 class="mb-2 font-medium  text-gray-900 dark:text-white">How much do you expect to use each month?</h3>
<ul class="grid w-full gap-6 md:grid-cols-2">

    <li>
        <input type="radio" id="hosting-big" name="hosting" value="hosting-big" class="hidden peer"/>
        <label for="hosting-big" class="flex flex-col items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
        <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 9L9.48545 17L26.4545 1" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            <div class="block">
                <div class="w-full text-lg font-semibold">Yes</div> 
            </div>


        </label>
    </li>
    <li>
        <input type="radio" id="hosting-big" name="hosting" value="hosting-big" class="hidden peer"/>
        <label for="hosting-big" class="flex flex-col items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 1L1 17M1.00003 1L17 17" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


            <div class="block ">
                <div class="w-full text-lg font-semibold">Female</div> 
            </div>
        </label>
    </li>
</ul>
</div>

<div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
        </div>
        <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
        </div>
        <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
        </div>
        <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" required />
        </div>
     <SelectInput/>



          </div>

        </div>
       </div>
      
    </div>
  )
}

export default Registartion